.container {
	left: 50%;
	transform: translateX(-50%);
	top: 0;
	visibility: hidden;
	opacity: 0;
	display: grid;
	grid-template-columns: 40px 40px auto 40px 40px;
	gap: 10px;
	background-color: transparent !important;
	backdrop-filter: unset !important;

	.button {
		position: relative;
		height: fit-content;
		padding: 8px;
		box-sizing: border-box;
		border-radius: 50%;
		cursor: pointer;

		& > div {
			display: flex;
		}
	}

	.statusWrapper {
		overflow: hidden;
		height: 40px;
		width: fit-content;
		transition: var(--transition);
		position: relative;
		// border-radius: 1111px;
		border-radius: 25px;

		&:hover {
			height: 120px;
		}
	}

	.wrapper {
		padding: 8px 12px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		cursor: pointer;
		gap: 8px;
	}
}

.visible {
	& > svg > path {
		fill: var(--mono100) !important;
	}
}
