.valueWrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	transition: background-color 0.3s ease-in-out;
	padding: 15px 10px 15px 5px;
	box-sizing: border-box;
	// overflow: hidden;
}

.selected {
	background-color: var(--elev400);
	height: 54px;
}

.additionalSelector {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-left: 35px;
	gap: 10px;

	& > span:last-of-type {
		max-width: 70%;
	}
}

.expand {
	height: auto;
}

.mediaAssigned {
	display: flex;
	align-items: center;
	gap: 8px;

	& > div {
		width: 24px;
		height: 24px;
		overflow: hidden;
		border-radius: 4px;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
