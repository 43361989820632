.container {
	@media (max-width: 768px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.playerWrapper {
	display: flex;
	width: 100%;
	height: calc(100% - 72px - 82px);
	justify-content: center;
	position: relative;

	&:hover {
		.control {
			opacity: 1;
			visibility: visible;
		}
	}

	& > video {
		border-radius: 15px;
	}

	.control {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
		transition: var(--transition);
		opacity: 0;
		visibility: hidden;
		display: flex;
		z-index: 1;
	}
}

.buttonWrapper {
	max-width: 300px;
	width: 100%;
	margin: auto;
}
