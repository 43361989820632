.dataWrapper {
	display: flex;
	gap: 60px;
	margin-bottom: 34px;

	.dataItem {
		display: flex;
		flex-direction: column;
		gap: 4px;

		.change {
			display: flex;
			align-items: center;
			gap: 10px;

			& > div {
				display: flex;
			}
		}
	}
}

.wrapper {
	overflow-x: scroll;
	overflow-y: hidden;
	white-space: nowrap;
}
