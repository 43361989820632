.container {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;

	.wrapper {
		position: relative;

		.image {
			display: flex;
			border-radius: 15px;
			overflow: hidden;
			cursor: pointer;
			border: 2px solid transparent;
			transition: var(--transition);
			position: relative;

			&:hover {
				border: 2px solid var(--theme-primary);

				& > div {
					visibility: visible;
					opacity: 1;
				}
			}

			& > div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				visibility: hidden;
				opacity: 0;
				transition: var(--transition);
				background-color: rgba(0, 0, 0, 0.7);
				display: flex;
				align-items: center;
				justify-content: center;

				& > span {
					color: var(--theme-primary);
					white-space: nowrap;
				}
			}

			& > img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.inputWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}
}
