.container {
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
	border: 2px dashed var(--mono100);
	margin-top: 4px;

	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.fileInput {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		cursor: pointer;
		opacity: 0;
	}
}

.circle {
	max-width: 164px;
	max-height: 164px;
	min-width: 164px;
	min-height: 164px;
	border-radius: 50%;
}
.circle-s {
	max-width: 146px;
	max-height: 146px;
	min-width: 146px;
	min-height: 146px;
	border-radius: 50%;
}

.rectangle {
	max-width: 116px;
	min-width: 116px;
	max-height: 206px;
	min-height: 206px;
	border-radius: 15px;
}
.rectangle-h {
	max-width: none;
	max-height: 206px;
	border-radius: 15px;
}

.square {
	max-width: 300px;
	max-height: 300px;
	border-radius: 15px;
}

.portrait {
	max-width: 136px;
	min-width: 146px;
	max-height: 206px;
	min-height: 206px;
	border-radius: 15px;
}
.portrait-v {
	max-width: calc(var(--sidebarWidth) / 2);
	width: 100%;
	max-height: calc(var(--sidebarWidth) / 2);
	min-height: calc(var(--sidebarWidth) / 2);
	height: 100%;
	border-radius: 15px;
}

.landscape {
	max-height: 116px;
	min-height: 116px;
	max-width: 206px;
	min-width: 206px;
	border-radius: 15px;
}

.filled {
	border: 2px dashed var(--theme-primary);

	& > div > span {
		color: var(--theme-primary);
		& > span {
			color: var(--theme-primary);
		}
	}
}
