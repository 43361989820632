.statusWrapper {
	display: flex;
	align-items: center;
	gap: 10px;
	position: absolute;
	z-index: 1;
	right: 10px;
	top: 10px;

	.status {
		position: relative;

		& > div {
			&:first-of-type {
				// padding: 16px;
				box-sizing: border-box;
				border-radius: 50%;
			}
			&:last-of-type {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				// width: 16px;
				// height: 16px;
				border-radius: 50%;
			}
		}

		.animation {
			animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
		}
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.33);
	}
	80%,
	100% {
		opacity: 0;
	}
}
