.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;

	.messageWrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: auto;
		overflow-x: auto;
	}

	.buttons {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-top: auto;

		& > button {
			width: 100%;
		}
	}
}
