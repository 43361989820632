.container {
	position: relative;

	.launch {
		@media (max-width: 1024px) {
			display: none;
		}
	}

	.burger {
		display: none;
		position: absolute;
		top: 50%;
		right: -10px;
		transform: translateY(-50%);
		flex-direction: column;
		justify-content: space-around;
		width: 14px;
		height: 14px;
		background-color: transparent;
		cursor: pointer;
		z-index: 3;
		padding: 10px;

		@media (max-width: 1024px) {
			display: flex;
		}

		& > div {
			width: 12px;
			height: 1px;
			background-color: var(--mono100);
			border-radius: 10px;
			transition: var(--transition);
			position: relative;
			transform-origin: 0.5px;
			align-self: flex-end;

			&:first-of-type {
				width: 8px;
				transform: rotate(0);
			}
			&:nth-of-type(2) {
				opacity: 1;
				transform: translateX(0);
			}
			&:nth-of-type(3) {
				width: 8px;
				transform: rotate(0);
			}
		}
	}
	.open {
		& > div {
			&:first-of-type {
				width: 14px;
				transform: rotate(45deg);
			}
			&:nth-of-type(2) {
				opacity: 0;
				transform: translateX(20px);
			}
			&:nth-of-type(3) {
				width: 14px;
				transform: rotate(-45deg);
			}
		}
	}

	.homeNav {
		display: none;
		position: fixed;
		top: 0;
		right: -100dvw;
		flex-direction: column;
		justify-content: center;
		width: 100dvw;
		height: 100dvh;
		background-color: var(--mono900);
		cursor: pointer;
		z-index: 2;
		transition: var(--transition);
		overflow: hidden;

		@media (max-width: 1024px) {
			display: flex;
		}

		.menu {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 44px;

			.menuTabs {
				& > div {
					display: flex;

					flex-direction: column;
					gap: 24px;
				}
			}
		}
	}
	.openMenu {
		right: 0;
	}
}
