.itemWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	width: 100%;
	position: relative;

	& > div:first-of-type {
		min-width: 25%;
	}
	& > div:last-of-type {
		width: 75%;
	}

	.fileWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 5px 0;
		min-width: unset;

		&:hover {
			& > div:last-of-type {
				visibility: visible;
				opacity: 1;
			}

			div[data-role='preview-control'] {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
