.container {
	display: flex;
	align-items: center;
	gap: 44px;
	justify-content: center;
	margin-top: 20px;

	.next {
		display: flex;
		align-items: center;
		gap: 10px;
	}
}
