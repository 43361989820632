.wrapper {
	display: flex;
	height: 100%;
	gap: 12px;
	position: relative;

	.letterList {
		width: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;
		margin-right: 10px;
		height: fit-content;

		& > span {
			cursor: pointer;
			line-height: 15px;
		}

		.letterDivider {
			width: 1px;
			height: 16px;
		}
	}
	.sticky {
		position: fixed;
		top: 20px;
	}

	.listWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;

		.list {
			display: flex;
			flex-direction: column;
			width: 100%;

			& > h4 {
				padding: 5px 10px;
				box-sizing: border-box;
			}

			.userList {
				grid-template-columns: repeat(
					auto-fill,
					minmax(var(--networkCardWidth), 1fr)
				);
				gap: 16px;
				width: 100%;
			}
		}
	}
}
