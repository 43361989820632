@import 'styles/_typography.scss';

.container {
	@extend .body1-m;
	min-height: 72px;
	border-top: 1px solid var(--elev400);
	margin-top: auto;
	margin-left: -44px;
	margin-right: -44px;
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--mono200);

	&:disabled {
		color: var(--mono400);
	}
}
