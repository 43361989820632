.wrapper {
	display: flex;
	gap: 5px;
	align-items: center;

	.addButton {
		cursor: pointer;
		display: flex;
		padding: 14px;
		box-sizing: border-box;
		transition: var(--transition);
		border-radius: 50%;
		overflow: hidden;
		min-width: 52px;

		&:hover {
			background-color: var(--elev400);
		}
	}

	.users {
		display: flex;
		align-items: center;
		overflow: auto;
		padding: 5px;
		box-sizing: border-box;

		.userWrapper {
			position: relative;
			border: 1px solid transparent;
			border-radius: 50%;

			&:hover {
				z-index: 10;
				border: 1px solid var(--mono100);

				.remove {
					display: flex;
				}
			}

			.remove {
				display: none;
				position: absolute;
				top: -5px;
				right: -5px;
				z-index: 1;
				background-color: var(--mono500);
				border-radius: 50%;
				cursor: pointer;
			}

			.user {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				overflow: hidden;
				cursor: pointer;

				& > img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}
