.autoplaySwiper {
	& > div {
		transition-timing-function: linear !important;
	}
}

.itemWrapper {
	border-radius: 36px;
	width: 100%;
	height: 137px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 44px;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
	text-align: center;

	@media (min-width: 768px) {
		width: 367px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}

	& > h4 {
		font-size: 20px;
		line-height: 24px;
		font-weight: 500;
	}
}

.item1 {
	background: radial-gradient(
		112.9% 100% at 99.81% 0%,
		rgba(249, 18, 137, 0.2) 0%,
		rgba(249, 18, 137, 0) 100%
	);

	&::after {
		background: linear-gradient(0deg, #1b1c24, #1b1c24);
	}
}

.item2 {
	background: radial-gradient(
		112.9% 100% at 99.81% 0%,
		rgba(86, 62, 243, 0.2) 0%,
		rgba(86, 62, 243, 0) 100%
	);

	&::after {
		background: linear-gradient(0deg, #1b1c24, #1b1c24);
	}
}

.item3 {
	background: radial-gradient(
		112.9% 100% at 99.81% 0%,
		rgba(48, 166, 44, 0.2) 0%,
		rgba(48, 166, 44, 0) 100%
	);

	&::after {
		background: linear-gradient(0deg, #1b1c24, #1b1c24);
	}
}
.item4 {
	background: radial-gradient(
		112.9% 100% at 99.81% 0%,
		rgba(23, 122, 182, 0.3) 0%,
		rgba(23, 122, 182, 0) 100%
	);

	&::after {
		background: linear-gradient(0deg, #1b1c24, #1b1c24);
	}
}

.item5 {
	@extend .item1;
}
.item6 {
	@extend .item2;
}
.item7 {
	@extend .item3;
}
.item8 {
	@extend .item4;
}
