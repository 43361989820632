@import 'styles/_typography.scss';

.container {
	max-height: calc(100dvh - var(--headerHeight) - var(--footerHeight));
	overflow: hidden;
	max-width: 720px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 768px) {
		& > div {
			padding: 0px;
			// padding-right: 0px;
		}
	}
}

.text {
	display: flex;
	flex-direction: column;
	gap: 12px;
	color: var(--mono100);
	@extend .body1-m;
	line-height: 20px;

	* {
		color: var(--mono100);
	}

	h2 {
		padding-top: 20px;
	}

	p,
	div,
	span,
	li {
		@extend .body2-m;
		line-height: 20px;
	}

	ul {
		padding-left: 30px;
	}

	// li {
	// 	margin-bottom: 8px;
	// }

	a {
		color: var(--theme-primary);
	}
}
