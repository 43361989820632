.container {
	border-radius: 28px;
	padding: 32px 44px;
	box-sizing: border-box;
	background-color: var(--mono600);
	width: 100%;
	height: 100%;

	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		height: 100%;
		overflow-y: auto;

		.header {
			display: flex;
			gap: 12px;

			.titleWrapper {
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}
	}
}
