@import 'styles/_functions.scss';

$track-height: 6px;
$thumb-size: 20px;

.container {
	align-items: center;
	gap: 8px;
	display: flex;
	flex-direction: column;
	width: 100%;

	.labelWrapper {
		justify-content: space-between;
		display: flex;
		width: 100%;
		padding-right: 20px;
		box-sizing: border-box;

		.checkWrapper {
			display: flex;
			align-items: center;
			gap: 10px;
		}

		& > span:last-of-type {
			color: var(--mono300);
		}
	}

	.wrapper {
		align-items: center;
		gap: 4px;
		width: 100%;
		display: flex;
		margin: auto;

		& > div {
			display: flex;
			cursor: pointer;
			padding: 5px;
			transition: var(--transition);
			border-radius: 50%;

			// &:hover {
			// 	background-color: bg-t(var(--theme-primary), 20%);
			// }
		}

		& > input {
			appearance: none;
			-webkit-appearance: none;
			width: 100%;
			border-radius: 25px;
			cursor: pointer;

			&:focus {
				outline: none;
			}

			&::-webkit-slider-runnable-track {
				width: 100%;
				height: $track-height;
				background: var(--mono400);
				border-radius: 25px;
			}
			&::-webkit-slider-thumb {
				height: $thumb-size;
				width: $thumb-size;
				border-radius: 50%;
				background: var(--theme-primary);
				-webkit-appearance: none;
				margin-top: -$track-height;
			}
			&:focus::-webkit-slider-runnable-track {
				background: var(--mono400);
			}
			&::-moz-range-track {
				width: 100%;
				height: $track-height;
				background: var(--mono400);
				border-radius: 25px;
			}
			&::-moz-range-thumb {
				height: $thumb-size;
				width: $thumb-size;
				border-radius: 50%;
				background: var(--theme-primary);
			}
			&::-ms-track {
				width: 100%;
				height: $track-height;
			}
			&::-ms-fill-lower {
				background: var(--mono400);
				border-radius: 50px;
			}
			&::-ms-fill-upper {
				background: var(--mono400);
				border-radius: 50px;
			}
			&::-ms-thumb {
				height: $thumb-size;
				width: $thumb-size;
				border-radius: 50%;
				background: var(--theme-primary);
			}
			&:focus::-ms-fill-lower {
				background: var(--mono400);
			}
			&:focus::-ms-fill-upper {
				background: var(--mono400);
			}
		}
	}
}
