$typography: 'h1-m' 'Comfortaa Medium' 48px 56px,
	'h2-m' 'Comfortaa Medium' 34px 48px, 'h3-m' 'Comfortaa Medium' 24px 34px,
	'h3-b' 'Comfortaa Bold' 24px 34px, 'h4-b' 'Comfortaa Bold' 20px 28px,
	'h4-m' 'Comfortaa Medium' 20px 28px, 'body1-b' 'Lato Bold' 16px 24px,
	'body1-m' 'Lato Regular' 16px 24px, 'body1-r' 'Lato Regular' 16px 24px 400,
	'body2-b' 'Lato Bold' 14px 16px, 'body2-s' 'Lato Regular' 14px 16px 600,
	'body2-m' 'Lato Regular' 14px 16px, 'body2-r' 'Lato Regular' 14px 16px 400,
	'caption-b' 'Lato Bold' 13px 16px, 'caption-s' 'Lato Light' 13px 16px 600,
	'caption-m' 'Lato Regular' 13px 16px, 'caption-r' 'Lato Regular' 13px 16px 400;

@each $name, $font, $size, $lineHeight, $weight in $typography {
	.#{$name} {
		font-family: $font;
		font-size: $size;
		line-height: $lineHeight;
		font-weight: $weight;
	}
}
