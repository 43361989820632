@import 'styles/_custom.scss';

.itemWrapper {
	display: flex;
	flex-direction: column;
	gap: 32px;

	@media (max-width: 768px) {
		gap: 20px;
	}

	.titles {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: start;
		gap: 8px;
		max-width: 100%;
		width: 100%;
		margin: 0 auto;

		& > h3 {
			font-size: 24px;
			line-height: 30px;
		}
		& > span {
			font-size: 16px;
			line-height: 19px;
		}

		@media (min-width: 768px) {
			max-width: 85%;
			gap: 12px;
			text-align: center;

			& > h3 {
				font-size: 32px;
				line-height: 40px;
			}
			& > span {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}

	.itemsLayout {
		display: grid;
		grid-template-columns: repeat((1, minmax(auto, 1fr)));
		gap: 12px;

		@media (min-width: 768px) {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
			gap: 24px;
		}
	}

	.itemsLayout-castingdirector {
		@media (min-width: 768px) {
			& > :nth-child(1) {
				grid-row: span 2;
			}
			& > :nth-child(2) {
				grid-column: span 2;
			}
			& > :nth-child(4) {
				grid-row: span 2;
			}
			& > :nth-child(5) {
				grid-column: span 2;
			}
		}
	}
	.itemsLayout-agency {
		@media (min-width: 768px) {
			& > :nth-child(1) {
				grid-column: span 2;
			}
			& > :nth-child(2) {
				grid-row: span 2;
			}
			& > :nth-child(3) {
				grid-row: span 2;
			}
			& > :nth-child(5) {
				grid-column: span 2;
			}
		}
	}
	.itemsLayout-landing {
		@media (min-width: 768px) {
			& > div {
				grid-column: span 1;
				grid-row: span 1;
			}
			& > :nth-child(1) {
				// grid-column: span 2;
				grid-row: span 2;
			}
			& > :nth-child(2) {
				grid-column: span 2;
				grid-row: span 1;
			}
			& > :nth-child(3) {
				grid-row: span 2;
			}
		}
	}
}
