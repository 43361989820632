.info {
	align-content: center;

	.desc {
		padding: 8px 0 16px;
		display: flex;
		align-items: center;
		gap: 12px;

		& > div {
			display: flex;
			align-items: center;
			gap: 4px;

			& > span {
				color: var(--mono300);
			}
		}
	}
}
