@mixin sizePhotos {
	width: 200px;
	height: 200px;

	@media (max-width: 600px) {
		width: 150px;
		height: 270px;
	}
}

@mixin sizeReelz {
	width: 250px;
	height: 140px;

	@media (max-width: 600px) {
		width: 210px;
		height: 120px;
	}
}

.container {
	display: grid;

	.wrapper {
		display: flex;
		gap: 20px;
		width: 100%;
		// padding-right: 32px;
		// margin-left: -16px;

		& > div {
			cursor: pointer;

			& > img {
				object-fit: cover;
				border-radius: 15px;
			}
		}

		.photos {
			@include sizePhotos;

			& > img {
				@include sizePhotos;
			}
		}
		.reelz {
			@include sizeReelz;

			& > img {
				@include sizeReelz;
			}
		}

		.sidebarreelz {
			width: 210px;
			height: 120px;

			& > img {
				width: 210px;
				height: 120px;
			}
		}

		.sidebarphotos {
			width: 150px;
			height: 270px;

			& > img {
				width: 150px;
				height: 270px;
			}
		}
	}
}
