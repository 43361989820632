.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 20px;
	// min-height: calc(100dvh - 50px);
	padding-bottom: 20px;
	box-sizing: border-box;
}

.onboardingWrapper {
	display: grid;
	grid-template-columns: 2fr 1fr;
	border-radius: 28px;
	overflow: hidden;

	@media (max-width: 768px) {
		display: flex;
		height: 100%;
	}

	.contentWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 42px;
		padding: 80px;
		box-sizing: border-box;
		overflow: hidden;

		@media (max-width: 768px) {
			padding: 20px;
			gap: 20px;
		}

		.titles {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			gap: 12px;

			& > span {
				color: var(--mono300);
			}

			@media (max-width: 768px) {
				& > h1 {
					font-size: 24px;
					line-height: 28px;
				}
				& > span {
					font-size: 12px;
					line-height: 16px;
				}
			}
		}
	}

	.imageWrapper {
		width: 100%;
		height: var(--contentCardHeight);

		@media (max-width: 768px) {
			display: none;
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.expItem {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 10px;

	.circleWrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;

		& > div {
			display: flex;
		}
	}

	@media (max-width: 768px) {
		flex-direction: row;

		& > span {
			font-size: 12px;
			line-height: 16px;
		}
	}
}

.wizard {
	width: 100%;

	@media (max-width: 768px) {
		& > div > div > div {
			height: auto !important;
		}
	}
}

.goalsWrapper {
	@media (max-width: 768px) {
		display: flex !important;
		flex-direction: column;
	}
}
