@import 'styles/_functions.scss';
@import 'styles/_custom.scss';
$asideWidth: calc(var(--tutorialWidth) + 12px);
$fullWidth: calc(100% - $asideWidth);

.container {
	width: 100%;
	padding: 0;
	overflow: hidden;
	transition: var(--transition);
	// max-height: var(--contentCardHeight);
	// height: calc(calc(100% - $asideWidth) / var(--videoAspect));

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		// z-index: -1;
	}

	.smallWrapper {
		// background-color: rgba(0, 0, 0, 0.3);
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 2;
		display: flex;
		flex-direction: column;
	}

	.wrapper {
		padding: 30px 20px;
		box-sizing: border-box;
		gap: 12px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		// background-color: rgba(0, 0, 0, 0.6);
		position: relative;
		z-index: 2;
		height: 100%;
	}

	.topSection {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	.head {
		display: flex;
		align-items: flex-start;
		gap: 10px;
		justify-content: space-between;
	}

	.info {
		display: flex;
		margin-top: auto;
		width: 100%;
		flex-direction: column;
		gap: 10px;

		.director {
			display: flex;
			flex-direction: column;
			gap: 12px;
			width: 100%;
		}
	}

	.tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 12px;

		.tag {
			padding: 2px 8px;
			box-sizing: border-box;
			// background-color: bg-t(var(--mono100), 12%);
			border-radius: 15px;
			border: 1px solid var(--mono200);
			white-space: nowrap;

			& > span {
				color: var(--mono200);
			}
		}
	}
}

.card {
	position: relative;
	min-height: 400px;
	height: 100%;
	cursor: pointer;
}

.aside {
	max-width: var(--tutorialWidth);
	position: absolute;
	right: 0;
	z-index: 1;
	height: 100%;
	// border-style: solid;
	// border-color: transparent;
	// border-width: 2px;
	max-height: calc(var(--contentCardHeight) + 64px);
}

.small {
	height: 250px;
	width: 150px;
	min-width: 150px;
	max-width: 150px;
	position: relative;
	border-radius: 15px;
	cursor: pointer;

	& > div {
		& > div {
			padding: 10px !important;
			box-sizing: border-box;

			.head {
				display: none;
			}
			& > h3 {
				font-size: 16px !important;
				white-space: normal;
			}

			.tags {
				display: none;
			}

			.info {
				& > div:first-of-type {
					& > span {
						display: none;
					}
					& > a {
						font-size: 14px !important;
					}
				}
				& > div:last-of-type {
					display: none;
				}
			}
		}
	}
}
