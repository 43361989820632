@mixin absolute {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 20px;

	.startButton {
		@include absolute;
	}

	.videoWrapper {
		border-radius: 15px;
		overflow: hidden;
		position: relative;
		height: calc(
			(var(--maxWidth) - 100px - var(--tutorialWidth)) / var(--videoAspect)
		);
		width: 100%;
		margin: auto;

		.textOverlay {
			background-color: rgba(0, 0, 0, 0.55);
			position: absolute;
			z-index: 1;
			left: 50%;
			bottom: 20px;
			transform: translate(-50%, 0);
			width: 75%;
			padding: 20px;
			box-sizing: border-box;
			border-radius: 15px;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
	}

	.finishedWrapper {
		@include absolute;
		text-align: center;
	}

	.recordButton {
		display: flex;
		margin: 0 auto;
	}
}
