@use 'sass:color';

$colors: (
	'mono100': rgb(232, 232, 232),
	// #E8E8E8
	'mono200': rgb(199, 199, 201),
	// #C7C7C9
	'mono300': rgb(154, 155, 157),
	// #9A9B9D
	'mono400': rgb(108, 108, 112),
	// #6C6C70
	'mono500': rgb(63, 64, 69),
	// #3F4045
	'mono600': rgb(21, 22, 28),
	// #15161C
	'mono700': rgb(18, 19, 24),
	// #121318
	'mono800': rgb(15, 16, 20),
	// #0F1014
	'mono900': rgb(12, 13, 16),
	// #0C0D10
	'mono000': rgb(9, 10, 13),
	// #090A0D
	'elev100': rgb(11, 12, 15),
	// #0B0C0F
	'elev200': rgb(21, 22, 28),
	// #15161C
	'elev300': rgb(27, 28, 36),
	// #1B1C24
	'elev400': rgb(35, 36, 45),
	// #23242D
	'elev500': rgb(46, 47, 56),
	// #2E2F38
	'electric-tangerine-main': rgb(255, 170, 0),
	'amber-flame-main': rgb(242, 51, 13),
	'ice-blue': rgb(0, 209, 255),
	'warning': rgb(249, 225, 12),
	'success': rgb(13, 234, 128),
	'error': rgb(223, 23, 119),
	'actor': rgb(255, 170, 0),
	'agency': rgb(219, 0, 255),
	'castingdirector': rgb(0, 209, 255),
	'contributor': rgb(39, 255, 190),
);

:root {
	@each $name, $color in $colors {
		--#{$name}: #{$color};
		// for safari use case: https://ambientimpact.com/web/snippets/safari-bug-with-gradients-that-fade-to-transparent
		// --#{$name}-transparent: #{color.change($color, $alpha: 0)};
	}
}
