.section {
	display: flex;
	flex-direction: column;
	gap: 20px;
	transition: var(--transition);
	overflow: hidden;

	.header {
		display: flex;
		align-items: center;
		gap: 10px;
		cursor: pointer;
		padding-left: 20px;
		padding-right: 20px;
		box-sizing: border-box;

		.count {
			margin-right: auto;
			background-color: var(--mono400);
			padding: 0 5px;
			box-sizing: border-box;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;

			& > span {
				color: var(--mono000);
			}
		}

		.expand {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-left: auto;

			& > span {
				cursor: pointer;
			}

			& > div {
				display: flex;
			}
		}
	}

	.fieldsWrapper {
		display: flex;
		flex-direction: column;
		gap: 20px;
		// overflow-y: scroll;
	}
}
