.container {
	max-width: 100%;
	position: relative;
	margin-left: -20px;
	margin-right: -20px;
	max-width: none;
	border-radius: 50px;
	overflow: hidden;
	padding: 60px 0;

	@media (min-width: 768px) {
		padding: 0;
		height: 350px;
	}

	.wrapper {
		position: relative;
		z-index: 1;
		border-radius: 20px;
		background-color: var(--elev200);
		max-width: 565px;
		width: calc(100% - 40px);
		padding: 12px 16px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4px;
		text-align: center;
		margin: auto;

		@media (min-width: 768px) {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 25px 32px;
		}

		& > button {
			margin-top: 12px;
		}

		& > h3 {
			font-size: 24px;
			line-height: 30px;
		}

		& > span {
			font-size: 16px;
			line-height: 19px;
			color: var(--mono300);
		}

		@media (min-width: 768px) {
			& > h3 {
				font-size: 28px;
				line-height: 42px;
			}
		}
	}

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;

		@media (min-width: 768px) {
			position: relative;
		}
	}
}
