.container {
	position: fixed;
	z-index: var(--modalIndex);
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	max-height: 100dvh;
	background-color: rgba(0, 0, 0, 0.7);

	.close {
		display: flex;
		padding: 10px;
		cursor: pointer;
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
	}

	.wrapper {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 20px;
		padding: 20px;
		box-sizing: border-box;
		// overflow: hidden; // disable for select component purpose
		width: 100%;
		max-width: var(--maxWidth);
	}
}
