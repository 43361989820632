.container {
	width: fit-content;
	left: 0px;
	top: 0;
	border-radius: 25px !important;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;

	.wrapper {
		padding: 8px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		cursor: pointer;
		gap: 4px;
	}
}
