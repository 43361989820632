.itemWrapper {
	display: flex;
	flex-direction: row;
	width: 350px;
	gap: 16px;
	align-items: center;
	cursor: pointer;

	.icon {
		border-radius: 50%;
		border: 1px solid var(--theme-primary);
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		width: 46px;
		height: 46px;
		align-items: center;
		justify-content: center;

		& > span {
			color: var(--theme-primary);
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		gap: 4px;
		max-width: 208px;
		width: 100%;
	}
}
