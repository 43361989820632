.footer {
	width: 100%;
	margin-top: auto;
	height: var(--footerHeight);
	position: relative;
	z-index: 1;

	@media (max-width: '768px') {
		height: auto;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		overflow: hidden;

		@media (max-width: '768px') {
			display: grid;
			grid-template-columns: repeat(2, minmax(auto, 1fr));
			justify-items: center;
		}

		.link {
			padding: 16px;
			cursor: pointer;

			@media (max-width: '768px') {
				padding: 8px;
			}

			&:hover {
				& > span {
					color: var(--mono300);
				}
			}

			& > span {
				transition: var(--transition);
				color: var(--mono400);
			}
		}
	}
}

.onboarding {
	@media (max-width: 768px) {
		visibility: hidden;
		opacity: 0;
	}
}
