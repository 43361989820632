@import 'styles/_functions.scss';

.container {
	position: absolute;
	z-index: 2;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 104px;
	// max-width: var(--sidebarWidth);
	border-radius: 15px;
}

.verticalButton {
	width: 4px;
	height: 132px;
	background-color: bg-t(var(--mono100), 50%);
	border-radius: 5px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	visibility: visible;
	opacity: 1;
	transition: var(--transition);
	cursor: pointer;
}

.hideVerticalButton {
	opacity: 0;
	visibility: hidden;
}

.extendLineSize {
	background-color: transparent;
	padding: 20px 10px;
	z-index: 3 !important;
}
