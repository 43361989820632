@import 'styles/_functions.scss';
$track-height: 2px;
$thumb-width: 2px;
$thumb-height: 16px;

.container {
	align-items: center;
	gap: 4px;
	width: 100%;
	display: flex;
	max-width: 100px;

	& > input {
		-webkit-appearance: none;
		width: 100%;
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&::-webkit-slider-runnable-track {
			width: 100%;
			height: $track-height;
			background: var(--mono300);
		}
		&::-webkit-slider-thumb {
			height: $thumb-height;
			width: $thumb-width;
			background: var(--mono100);
			-webkit-appearance: none;
			transform: translateY(calc(-50% + $track-height / 2));
		}
		&:focus::-webkit-slider-runnable-track {
			background: var(--mono300);
		}
		&::-moz-range-track {
			width: 100%;
			height: $track-height;
			background: var(--mono300);
		}
		&::-moz-range-thumb {
			height: $thumb-height;
			width: $thumb-width;
			background: var(--mono100);
		}
		&::-ms-track {
			width: 100%;
			height: $track-height;
		}
		&::-ms-fill-lower {
			background: var(--mono300);
		}
		&::-ms-fill-upper {
			background: var(--mono300);
		}
		&::-ms-thumb {
			height: $thumb-height;
			width: $thumb-width;
			background: var(--mono100);
		}
		&:focus::-ms-fill-lower {
			background: var(--mono300);
		}
		&:focus::-ms-fill-upper {
			background: var(--mono300);
		}
	}
}
