.wrapper {
	transition: var(--transition);
	cursor: pointer;
	padding: 12px 16px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	gap: 12px;

	&:hover {
		background-color: var(--elev400);
	}
}

.titles {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 4px;
}
