@import 'styles/_functions.scss';
@import 'styles/_custom.scss';
$asideWidth: calc(var(--tutorialWidth) + 12px);
$fullWidth: calc(100% - $asideWidth);
$avatarWidth: 94px;

.container {
	width: 100%;
	padding: 0;
	overflow: hidden;
	transition: var(--transition);
	// max-height: var(--contentCardHeight);
	// height: calc(calc(100% - $asideWidth) / var(--videoAspect));
	border-radius: 15px;

	& > div > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		// z-index: -1;
	}

	.agencyAvatar {
		position: absolute;
		z-index: 1;
		left: 10px;
		top: 10px;
		width: 72px;
		height: 72px;
		border-radius: 50%;
		overflow: hidden;
		border: 2px solid var(--agency);

		& > img {
			width: 72px;
			height: 72px;
		}
	}

	.wrapper {
		padding: 30px 20px;
		box-sizing: border-box;
		gap: 24px;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 2;
		height: 100%;

		span,
		h4 {
			color: var(--mono200);
		}

		.head {
			display: flex;
			flex-direction: column;
			gap: 10px;
		}

		.avatar {
			width: 94px;
			height: 94px;
			overflow: hidden;
			border-radius: 15px;

			& > img {
				width: 94px;
				height: 94px;
				object-fit: cover;
			}
		}

		.itemWrapper {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
}

.card {
	position: relative;
	height: 100%;
	cursor: pointer;

	.wrapper {
		padding: 16px 20px !important;

		.head {
			position: relative;

			.avatar {
				width: 72px;
				height: 72px;

				& > img {
					width: 72px;
					height: 72px;
				}
			}

			.nda {
				position: absolute;
				top: 20px;
				right: 0;
				border: 1px solid var(--success);
				border-radius: 15px;

				& > div {
					display: flex;
					align-items: center;
					padding: 2px 5px 2px 10px;
					box-sizing: border-box;

					& > span {
						color: var(--success);
					}
				}
			}
		}
	}
	h2 {
		font-size: 24px;
		line-height: 30px;
	}

	.wrapper {
		gap: 12px;
		padding: 20px;
	}

	h4 {
		font-size: 16px;
		@extend .textClamp;
		-webkit-line-clamp: 2;
	}
}

.casting {
	position: relative;
	height: 100%;
	cursor: pointer;
	min-height: 330px;

	.wrapper {
		padding: 16px 20px !important;

		.head {
			position: relative;
			margin-top: auto;
		}
	}
}

.aside {
	max-width: var(--tutorialWidth);
	position: absolute;
	right: 0;
	z-index: 1;
	height: 100%;
}

.horizontal {
	max-height: 125px;
	width: 100%;
	position: relative;
	width: 100%;

	.wrapper {
		padding: 12px;
		gap: 10px;

		.head {
			flex-direction: row;
			align-items: center;
			gap: 16px;

			.avatar {
				width: 72px;
				min-width: 72px;
				height: 72px;

				& > img {
					width: 72px;
					height: 72px;
				}
			}
		}
	}
}
