@import 'styles/_functions.scss';
$asideWidth: calc(var(--tutorialWidth) + 12px);
$fullWidth: calc(100% - $asideWidth);

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	max-width: var(--tutorialWidth);
	// max-width: 267px;
	width: 100%;
	padding: 0;
	overflow: hidden;
	transition: var(--transition);
	position: absolute !important;
	right: 0;
	z-index: 1;
	height: 100%;
	// max-height: var(--contentCardHeight);
	// height: calc(calc(100% - $asideWidth) / var(--videoAspect));

	.close {
		position: absolute;
		top: 20px;
		right: 20px;
		color: var(--mono200);
		opacity: 0;
		visibility: hidden;
		mix-blend-mode: difference;
		cursor: pointer;
	}
	.showClose {
		opacity: 1;
		visibility: visible;
	}

	.description {
		// background-color: bg-t(var(--mono900), 60%);
		position: absolute;
		// max-width: calc(100% - 48px);
		width: 100%;
		height: 100%;
		opacity: 1;
		visibility: visible;

		.descContent {
			width: 100%;
			height: 100%;
			max-width: calc(100% - 20px);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 4px;
			z-index: 1;
			position: absolute;
			gap: 20px;

			* {
				word-break: break-word;
				color: var(--mono200);
			}
		}
	}

	.hideDesc {
		opacity: 0;
		visibility: hidden;
	}
}
.open {
	max-width: 100%;
	z-index: var(--tutorialOpenIndex);
}

.video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
