.container {
	padding: unset !important;
	background-color: unset !important;
	display: flex;
	flex-direction: column;
	gap: 24px;

	@media (max-width: 600px) {
		padding: 20px !important;
		background-color: var(--elev300) !important;
	}
}

.sidebar {
	padding: 20px !important;
	background-color: var(--elev300) !important;
}
