@import 'styles/_functions.scss';

.container {
	background-color: var(--mono900);
	position: fixed;
	width: 100dvw;
	height: 100dvh;
	top: 0;
	left: 0;
	transition: var(--transition);
	z-index: var(--loaderIndex);
	opacity: 0;
	visibility: hidden;
	z-index: -1;
}
.show {
	z-index: var(--loaderIndex);
	opacity: 1;
	visibility: visible;
}
.compressed {
	position: initial;
	// position: absolute;
	// top: 50%;
	// left: 50%;
	// transform: translate(-50%, -50%);
	width: unset;
	height: unset;
}
.adapt {
	width: 100%;
	height: 100%;
	background-color: bg-t(var(--mono900), 70%);
}
.visible {
	background-color: bg-t(var(--mono900), 70%);
}

.wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 30px;
	height: 30px;

	&:before {
		content: '';
		position: relative;
		display: block;
		width: 300%;
		height: 300%;
		box-sizing: border-box;
		margin-left: -100%;
		margin-top: -100%;
		border-radius: 45px;
		background-color: var(--theme-primary);
		animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		background-color: var(--theme-primary);
		border-radius: 15px;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
		animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(0.33);
	}
	80%,
	100% {
		opacity: 0;
	}
}

@keyframes pulse-dot {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.8);
	}
}
