@import 'styles/_functions.scss';
$margin: 60px;

.container {
	border-radius: 25px;
	border: 2px solid var(--mono200);
	margin-top: $margin;
	background-color: var(--elev400);

	.wrapper {
		padding: 0 30px 30px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		.avatar {
			transform: translateY(-$margin);
			margin-bottom: -$margin;
			border: 2px solid var(--mono200);
			width: 120px;
			height: 120px;
			overflow: hidden;
			border-radius: 50%;
		}

		.tags {
			display: flex;
			align-items: center;
			gap: 12px;

			.tag {
				background-color: var(--mono400);
				padding: 2px 8px;
				box-sizing: border-box;
				border-radius: 15px;
			}
		}

		.code {
			padding: 10px 0;
			& > img {
				max-width: 200px;
			}
		}

		.itemWrapper {
			display: flex;
			flex-direction: column;
			width: 100%;

			.item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 8px;

				& > span {
					color: var(--mono200);
				}
			}
		}
	}
}

.wallet {
	border-radius: 25px;
	overflow: hidden;
	border: 1px solid var(--mono100);
	cursor: pointer;

	& > img {
		width: 100%;
		height: 100%;
	}
}

.shareButton {
	padding: 8px;
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	width: fit-content;
	transition: var(--transition);
	margin: auto;

	&:hover {
		background-color: bg-t(var(--mono500), 40%);
	}

	& > button {
		display: flex;
	}
}
