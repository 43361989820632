.container {
	background-color: var(--elev300);
	border-radius: 15px;
	width: 100%;
	position: relative;
	height: 100%;

	.wrapper {
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		gap: 32px;
		cursor: pointer;
		height: 100%;
		overflow: hidden;

		.media {
			height: 100%;
			width: 100%;
			overflow: hidden;
			position: relative;
			border-radius: 15px;

			& > div {
				display: flex;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 1;
			}

			& > img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.pinned {
			border: 2px solid var(--theme-primary);
		}
		.showreel {
			max-width: 176px;
			min-width: 176px;
			max-height: 136px;
			min-height: 136px;
		}
		.picture {
			max-width: 136px;
			min-width: 136px;
			max-height: 176px;
			min-height: 176px;

			@media (max-height: 900px) {
				max-height: 100%;
				min-height: 100%;
			}
		}

		.contentWrapper {
			display: flex;
			flex-direction: column;
			gap: 13px;
			justify-content: center;
			width: 100%;

			.title {
				display: flex;
				align-items: center;
				gap: 5px;
			}

			.values {
				display: flex;
				flex-direction: column;
				gap: 4px;

				.value {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 5px;

					& > span {
						text-align: end;
					}
				}
			}
		}
	}
}
