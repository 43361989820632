@import 'styles/_custom.scss';

.container {
	@media (max-width: 600px) {
		margin-top: 100px;
	}
}

.header {
	display: flex;
	gap: 20px;

	.avatar {
		width: 120px;
		height: 174px;
		border-radius: 15px;
		overflow: hidden;
		border: 2px solid transparent;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media (max-width: 600px) {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 100%;
			height: 40dvh;
			border-radius: unset;
			border: none;
		}
	}

	.actionsWrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-left: auto;
		height: 100%;
	}
}

.sidebar {
	margin-top: 90px;

	.header .avatar {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 350px;
		border-radius: unset;
		border: none;
	}

	.bioWrapper {
		flex-direction: row;
		gap: 10px;
		width: 100%;

		& > div:last-of-type {
			display: flex;
		}
	}
}
