@import 'styles/_functions.scss';

.tab {
	padding: 12px 28px;
	box-sizing: border-box;
	border-radius: 25px;
	cursor: pointer;
	transition: var(--transition);

	@media (max-width: '768px') {
		padding: 8px 12px;
	}

	& > span {
		text-transform: capitalize;
		white-space: nowrap;
		transition: var(--transition);
	}

	&:hover {
		background-color: bg-t(var(--theme-primary), 20%);

		& > span {
			color: var(--theme-primary);
		}
	}
}

.active {
	background-color: bg-t(var(--theme-primary), 20%);

	& > span {
		color: var(--theme-primary);
		transition: var(--transition);
	}

	& > div {
		background-color: var(--theme-primary);
	}
}
