@import 'styles/_functions.scss';

.container {
	display: grid;
	grid-template-columns: 217px 1fr;
	gap: 40px;
	width: 100%;

	.avatar {
		width: 217px;
		height: 302px;
		border-radius: 24px;
		overflow: hidden;
		border: 2px solid transparent;
		position: relative;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.shareButton {
			position: absolute;
			top: 5px;
			right: 5px;
			z-index: 1;
			display: flex;
			padding: 10px;
			box-sizing: border-box;
			cursor: pointer;
			background-color: transparent;
			transition: var(--transition);
			border-radius: 50%;
			overflow: hidden;

			&:hover {
				background-color: bg-t(var(--elev400), 50%);
			}
		}
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		// padding: 30px 0;
		// box-sizing: border-box;
		overflow: hidden;
		width: 100%;
		gap: 10px;

		.validation {
			display: flex;
			align-items: center;
			gap: 16px;
		}
	}
}
