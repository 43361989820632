@import 'styles/_typography.scss';
@import 'styles/_functions.scss';
@import 'styles/_custom.scss';

.container {
	display: flex;
	position: relative;
	background-color: var(--mono600);
	border-radius: 15px;
	width: 100%;
}

.picker {
	// height: 290px !important;
	width: 100% !important;

	& > div:first-of-type {
		margin-top: 4px;
		padding: 0px;

		& > div:first-of-type {
			margin-right: 0;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			background-color: var(--elev400);
			padding-left: 15px;
			border-radius: 25px;

			* {
				color: var(--mono200);
				@extend .textEllipsis;
			}
		}
		& > div:last-of-type {
			justify-content: space-between;
			width: 100%;
		}

		path {
			fill: var(--mono200);
		}
	}

	div[role='row'] {
		justify-content: space-around;
	}
	button[role='gridcell'],
	button[role='radio'] {
		color: var(--mono200);

		@extend .body1-m;

		&:hover {
			background-color: bg-t(var(--theme-primary), 20%);
		}

		&[aria-selected='true'],
		&[aria-checked='true'] {
			background-color: bg-t(var(--theme-primary), 80%) !important;
			color: var(--mono600);
		}

		&[aria-current='date'] {
			border: 1px solid var(--theme-primary);
		}

		&:disabled {
			background-color: var(--mono500);
		}
	}
	span[role='columnheader'] {
		color: var(--mono400);
	}
}
