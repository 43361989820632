@import 'styles/_functions.scss';

.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}

.label {
	padding-left: 20px;
	color: var(--mono200);
	padding-bottom: 8px;
}

.textarea {
	background-color: var(--elev400);
	padding: 12px 20px;
	box-sizing: border-box;
	border-radius: 15px;
	resize: none;
	color: var(--mono200);
	border: 1px solid transparent;

	&:disabled {
		color: var(--mono400);
	}
}

.limit {
	position: absolute;
	top: 100%;
	right: 5px;
	color: var(--mono400);
}
