@import 'styles/_functions.scss';

.flex {
	display: flex;
	justify-content: center;
}

.inputLabel {
	padding-left: 20px;
	color: var(--mono200);
	padding-bottom: 8px;
}

$grids: 2, 3, 4, 5, 6;

@each $count in $grids {
	.grid#{$count}column {
		display: grid;
		grid-template-columns: repeat($count, minmax(0, 1fr));
		gap: 20px;
	}
}

.textEllipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.textClamp {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: unset;
	-webkit-line-clamp: 3;
	overflow: hidden;
}

.prewrap {
	white-space: pre-wrap;
}

.card {
	background-color: var(--elev400);
	padding: 20px;
	box-sizing: border-box;
	border-radius: 15px;
}

.br {
	border-radius: 15px;
}

.cardHeight {
	height: var(--contentCardHeight);
}

.lcmw {
	max-width: calc(100% - 88px); // 88 = Card component side paddings
}

.horizontalScroll {
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
}

.hideScrollBar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&::-webkit-scrollbar {
		display: none;
	}
}

.labelPadding {
	padding-left: 20px;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.hoverWrapperParent {
	&:hover {
		.hoverWrapper {
			visibility: visible;
			opacity: 1;
		}
	}
}

.hoverWrapper {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	width: 101%;
	height: 101%;
	top: 0;
	left: 0;
	transition: var(--transition);
	background-color: bg-t(var(--mono900), 100%);
	z-index: 3;

	.hoverContent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 20px;
		height: 100%;
		padding: 10px;
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.hoverContentHorizontal {
		flex-direction: row;
		align-items: center;
	}
}

.hoverButtonWrapper {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;

	& > svg > path,
	& > span {
		transition: var(--transition);
		white-space: nowrap;
	}

	&:hover {
		& > svg > path {
			fill: var(--theme-primary);
		}
		& > span {
			color: var(--theme-primary);
		}
	}
}

.hoverButtonIcon {
	padding: 12px;
	box-sizing: border-box;
	border: 1px solid transparent;
	border-radius: 50%;
	transition: var(--transition);

	&:hover {
		border: 1px solid var(--theme-primary);
	}
}

.highlight {
	animation: blink 1.5s normal;
	animation-fill-mode: both;
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.flag {
	display: flex;
	border-radius: 50%;
	overflow: hidden;
	width: 20px;
	height: 20px;
	position: relative;

	& > img {
		width: 30px;
		height: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.cardWrapperSize {
	height: var(--contentCardHeight);
	min-height: var(--contentCardHeight);

	@media (max-height: 900px) {
		height: calc(
			100dvh - 97px - 51px - 48px - 50px
		); // 97px -header size; 51px - footer size; 48px - control buttons;  50px - just more to fit;
		min-height: unset;
	}
}

.cardWrapperSizeNoControls {
	height: calc(var(--contentCardHeight) + 88px);
	min-height: calc(var(--contentCardHeight) + 88px);

	@media (max-height: 900px) {
		height: calc(
			100dvh - 97px - 51px - 10px
		); // 97px -header size; 51px - footer size; 48px - control buttons;  50px - just more to fit;
		min-height: unset;
	}
}

.castingToolWrapper {
	backdrop-filter: blur(3px);
	background-color: color-mix(in srgb, var(--mono000) 64%, transparent);
	// width: 100%;
	// height: 100%;
	position: absolute;
	border-radius: 15px;
	// overflow: hidden;
	// visibility: hidden;
	// opacity: 0;
	transition: var(--transition);
}

.show {
	visibility: visible;
	opacity: 1;
}

.Toastify__close-button {
	opacity: 1;

	& > svg {
		& > path {
			fill: var(--theme-primary);
			opacity: 1;
		}
	}
}

.cardShadowBackground {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	// background:
	// 	'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, #000000 75%, transparent) -50%, color-mix(in srgb, #000000 0%, transparent) 100%)',
	background: radial-gradient(
		112.9% 100% at 99.72% 0%,
		color-mix(in srgb, rgb(0, 0, 0) 10%, transparent) 0%,
		color-mix(in srgb, rgb(0, 0, 0) 95%, transparent) 100%
	);
	// background:
	// 	'radial-gradient(112.9% 100% at 99.72% 0%, color-mix(in srgb, var(--mono900) 75%, transparent) -50%, color-mix(in srgb, var(--mono900) 50%, transparent) 100%)',
	z-index: 1;
}

.playerLayoutContainer {
	width: 100%;
	height: 100%;
	position: relative;

	&:hover {
		div[data-role='controls'] {
			visibility: visible;
			opacity: 1;
		}

		div[data-role='decisions'] {
			visibility: visible;
			opacity: 1;
		}

		div[data-role='content-actions'] {
			visibility: visible;
			opacity: 1;
		}

		div[data-role='casting-back'] {
			visibility: visible;
			opacity: 1;
		}

		div[data-role='subtitles'] {
			bottom: 190px;
		}
	}
}

.responsiveGrid {
	@extend .grid5column;

	@media (max-width: 1300px) {
		grid-template-columns: repeat(4, minmax(auto, 1fr));
	}
	@media (max-width: 1100px) {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
	@media (max-width: 900px) {
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}

.inputLoadingAnimation {
	animation: rotation 2s linear infinite;
}

@keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
