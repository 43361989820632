.container {
	display: flex;
	align-items: center;
	gap: 10px;

	.color {
		width: 20px;
		min-width: 20px;
		height: 20px;
		border-radius: 50%;
		overflow: hidden;
		border: 1px solid var(--theme-primary);
	}

	& > span {
		cursor: pointer;
		color: var(--mono200);
	}
}
