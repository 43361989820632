// .focus {
// 	border: 2px solid var(--mono200);
// 	outline: none;
// }

video {
	cursor: pointer;
}

button {
	outline: none;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

textarea {
	border-radius: 0;
	border: 0;
}

input {
	padding: 0;
	margin: 0;
	outline: none;
	border: none;
}
input[type='radio'],
input[type='checkbox'] {
	cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0;
	margin: 0;
}

ul {
	margin: 0;
}
