@import 'styles/_functions.scss';

.progress {
	height: 3px;
	width: 150px;
	background-color: var(--theme-primary);
	position: absolute;
	left: 0;
	top: 0;
}

.header {
	display: grid;
	grid-template-columns: 200px 1fr 200px;
	padding: 10px 0px;
	box-sizing: border-box;
	width: 100%;
	gap: 10px;
	height: var(--headerHeight);
	align-content: center;
}

.sticky {
	position: sticky;
	top: 0;
	width: unset;
	z-index: var(--headerIndex);
	backdrop-filter: blur(12px);
}

.home {
	@media (max-width: 1024px) {
		grid-template-columns: 1fr;
		& > div:first-of-type {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	margin-right: -20px;
	margin-left: -20px;
	padding: 10px 30px;
}

.hide {
	& > div {
		&:nth-child(2),
		&:nth-child(3) {
			display: none;
		}
	}
}

.logoAlign {
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.homeTabs {
	@media (max-width: 1024px) {
		display: none;
	}
}
