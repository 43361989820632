.container {
	padding: 24px 16px;
	box-sizing: border-box;

	@media (max-width: 600px) {
		padding: 24px 0px;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}

.sidebar {
	padding: 0px 0px 24px;
}
