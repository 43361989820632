@import 'styles/_functions.scss';

.container {
	left: 50%;
	bottom: 20px;
	transform: translateX(-50%);
	display: flex;
	position: absolute;

	& > h2 {
		box-sizing: border-box;
		color: var(--theme-primary);
		text-align: center;
	}
}
