.container {
	display: flex;
	flex-direction: column;
	gap: 24px;

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.row {
			display: flex;
			align-items: center;
			column-gap: 20px;
		}
	}

	.tags {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}
