@import 'styles/_functions.scss';

.buttons {
	display: flex;
	align-items: center;
	gap: 24px;
	margin-top: auto;

	& > span:not(:last-of-type) {
		padding: 12px 28px;
		box-sizing: border-box;
		cursor: pointer;
		transition: var(--transition);
		background-color: transparent;
		border-radius: 15px;

		&:hover {
			background-color: bg-t(var(--mono500), 20%);
		}
	}
}
