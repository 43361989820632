.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  position: relative;

  &:hover {
    .remove {
      visibility: visible;
      opacity: 1;
    }
  }

  .remove {
    transition: var(--transition);
    display: flex;
    background-color: var(--elev400);
    position: absolute;
    z-index: 1;
    top: -5px;
    right: -5px;
    border-radius: 50%;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
  }

  .image {
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
}
