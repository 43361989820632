.notify {
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
	margin-top: 10px;
	text-decoration: underline;
}

.container {
	display: flex;
	gap: 16px;
	padding-top: 5px;
	overflow: auto;
}
