@import 'styles/_functions.scss';

.options {
	width: 150px;
	max-height: 200px;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	z-index: 3;
	position: relative !important;

	.option {
		padding: 10px 20px;
		box-sizing: border-box;
		transition: var(--transition);
		background-color: transparent;
		cursor: pointer;

		&:hover {
			background-color: bg-t(var(--mono100), 10%);
		}
	}

	.selected {
		background-color: bg-t(var(--mono100), 10%);
	}
}
