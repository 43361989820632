@import 'styles/_functions.scss';

.user {
	display: flex;
	align-items: center;
	gap: 12px;
	overflow: hidden;
	cursor: pointer;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 15px;
	background-color: transparent;

	// &:hover {
	// 	background-color: var(--elev400);
	// }

	.avatar {
		height: 48px;
		width: 48px;
		min-width: 48px;
		border-radius: 50%;
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
		gap: 2px;
		max-width: calc(100% - 85px);
	}
}
.active {
	background-color: bg-t(var(--mono100), 10%);
}
