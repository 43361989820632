@import 'styles/_functions.scss';
$iconsNavSize: 64px; // 48px - icon + 8*2 - paddings

.container {
	// height: 100%;
	width: $iconsNavSize;
	min-width: $iconsNavSize;
	cursor: pointer;
	// position: fixed;
	// position: sticky;
	// position: -webkit-sticky;
	// top: 50%;
	// transform: translateY(-50%);
	// left: 20px;
	// height: var(--contentCardHeight);
	z-index: var(--navIndex);
	position: relative;

	.wrapper {
		padding: 12px 8px;
		box-sizing: border-box;
		transition: var(--transition);
		width: $iconsNavSize;
		display: flex;
		flex-direction: column;
		gap: 24px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: var(--elev200);
		border-radius: calc($iconsNavSize / 2);
		height: fit-content;

		.item {
			overflow: hidden;
			display: flex;
			align-items: center;
			border-radius: 50px;
			gap: 24px;
			padding-right: 24px;
			box-sizing: border-box;
			transition: var(--transition);

			& > div {
				padding: 12px;
				box-sizing: border-box;
				display: flex;

				& > svg > path,
				& > svg > g > path {
					transition: var(--transition);
					fill: var(--mono400);
				}
			}

			& > span {
				transition: var(--transition);
				color: var(--mono400);
			}

			&:hover {
				background-color: bg-t(var(--theme-primary), 20%);

				& > span {
					color: var(--theme-primary);
				}

				& > div > svg > path,
				& > div > svg > g > path {
					fill: var(--theme-primary);
				}
			}
		}

		.active {
			background-color: bg-t(var(--theme-primary), 20%);

			& > div {
				& > svg > path,
				& > svg > g > path {
					fill: var(--theme-primary);
				}
			}

			& > span {
				color: var(--theme-primary);
			}
		}
	}
	.full {
		width: 200px;
	}
}
