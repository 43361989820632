.itemWrapper {
	display: flex;
	gap: 44px;
	height: 100%;
	width: 100%;

	& > div:first-of-type {
		min-width: 25%;
	}
	& > div:last-of-type {
		width: 100%;
	}

	.fileWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.info {
		align-content: center;

		.desc {
			padding: 8px 0 16px;
			// display: flex;
			// align-items: center;
			gap: 12px;

			& > div {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				& > span {
					color: var(--mono300);
				}
			}
		}
	}
}

.vertical {
	flex-direction: column;
	gap: 10px;
}
