.wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.info {
		gap: 4px;
		display: flex;
		flex-direction: column;

		.gender {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}

.desc {
	display: flex;
}

.item {
	display: flex;
	flex-direction: column;
	gap: 8px;

	& > span {
		&:first-of-type {
			color: var(--mono300);
		}
		&:last-of-type {
			color: var(--mono200);
		}
	}

	& > span {
		color: var(--mono200);
	}
}

.activeTab {
	span {
		color: var(--theme-primary) !important;
	}
	path {
		fill: var(--theme-primary);
	}
	div {
		border-color: var(--theme-primary) !important;
	}
}
