.wrapper {
	padding: 20px;
	box-sizing: border-box;
	border-radius: 25px;
	background-color: var(--elev400);
	max-width: 800px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
}
