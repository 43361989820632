.container {
	background-color: var(--elev200);
	width: 100%;
	height: 100%;
	border-radius: 44px;

	.wrapper {
		padding: 105px 120px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;

		@media (max-width: 1000px) {
			padding: 50px;
		}
		@media (max-width: 768px) {
			padding: 24px 16px;

			& > div:first-of-type {
				margin: auto;
			}
		}

		.linksWrapper {
			padding: 44px 131.5px 32px;
			box-sizing: border-box;

			@media (max-width: 1350px) {
				padding-left: 70px;
				padding-right: 70px;
			}
			@media (max-width: 1200px) {
				padding-left: 0px;
				padding-right: 0px;
			}

			@media (max-width: 800px) {
				grid-template-columns: repeat(2, minmax(auto, 1fr));
			}
			@media (max-width: 768px) {
				display: flex;
				flex-direction: column;
				padding: 0;
				padding-top: 20px;
				gap: 24px;

				& > div {
					order: 2;
				}

				.joinCard {
					// margin-top: 0px;
					order: 1;
				}
			}
		}

		.links {
			display: flex;
			flex-direction: column;

			& > span {
				padding: 24px 0;
				box-sizing: border-box;

				@media (max-width: 768px) {
					padding: 0 0 8px;
				}
			}

			& > div {
				display: flex;
				flex-direction: column;
				gap: 10px;

				& > span {
					color: var(--mono300);
					cursor: pointer;
					transition: var(--transition);

					&:hover {
						color: var(--mono200);
					}
				}
			}
		}

		.joinCard {
			margin-top: 24px;
			grid-column: span 2;
			background-color: var(--elev400);
			border-radius: 20px;
			height: fit-content;

			@media (max-width: 800px) {
				grid-column: span 1;
			}

			.card {
				padding: 24px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				gap: 24px;

				& > button {
					width: fit-content;
				}
			}
		}

		.socialsWrapper {
			display: flex;
			align-items: center;
			gap: 32px;
			margin: 0 auto;

			@media (max-width: 768px) {
				padding-top: 20px;
			}

			& > div {
				cursor: pointer;
				display: flex;

				& > svg > path {
					transition: var(--transition);
				}

				&:hover {
					& > svg > path:first-of-type {
						fill: var(--mono200);
					}
				}
			}
		}
	}
}
