@import 'styles/_functions.scss';
$imageSize: 534px;

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: unset;
	width: 100%;
	height: 100%;

	@media (min-width: 768px) {
		justify-content: space-around;
	}
}

.wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	gap: 32px;
}

.contentWrapper {
	display: flex;
	width: 100%;
	position: relative;
	height: 100%;

	@media (min-width: 768px) {
		height: unset;
	}
}

.discover {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.imageBase {
	border-radius: 44px;
	max-width: $imageSize;
	max-height: $imageSize;
	overflow: hidden;
}

.itemsWrapper {
	box-sizing: border-box;
	padding: 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 92px;
	position: relative;
	overflow: hidden;
	height: 100%;

	@media (min-width: 768px) {
		padding: 20px 70px;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: 12px;
		position: relative;
		z-index: 1;
		width: 100%;
		overflow: auto;
		max-height: 100%;
		height: 100%;
		justify-content: space-evenly;

		& > h2 {
			font-size: 34px;
			line-height: 38px;
			font-weight: 500;
		}
		& > span {
			font-size: 18px;
			line-height: 22px;
			color: var(--mono300);
		}

		@media (min-width: 768px) {
			gap: 20px;
			align-items: flex-start;
			text-align: unset;
			justify-content: center;

			& > h2 {
				font-size: 49px;
				line-height: 55px;
			}
			& > span {
				font-size: 24px;
				line-height: 28px;
				color: var(--mono200);
			}
		}

		.mobileImage {
			width: 100%;
			position: relative;
			z-index: 1;
			height: 100%;
			border-radius: 44px;
			overflow: hidden;
			max-height: 250px;
			height: 100%;

			@media (min-width: 768px) {
				display: none;
			}

			& > img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.buttons {
			display: flex;
			align-items: center;
			flex-direction: row;
			gap: 8px;

			@media (min-width: 768px) {
				gap: 32px;
				margin-top: 12px;
			}
			& > button {
				width: 100%;
				white-space: nowrap;
				padding-left: 16px;
				padding-right: 16px;

				@media (min-width: 768px) {
					padding-left: 32px;
					padding-right: 32px;
				}
			}
		}
	}

	.desktopImage {
		width: 100%;
		position: relative;
		z-index: 1;
		@extend .imageBase;
		display: none;

		@media (min-width: 768px) {
			display: block;
		}

		& > img {
			width: 100%;
		}
	}
}

.arrowRight {
	right: 0px;
}
.arrowLeft {
	left: 0px;
}
