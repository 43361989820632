.wrapper {
	display: flex;
	align-items: center;
	gap: 12px;

	.avatar {
		width: 44px;
		min-width: 44px;
		height: 44px;
		border-radius: 50%;
		overflow: hidden;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.info {
		display: flex;
		flex-direction: column;
		gap: 2px;

		& > div {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}
