.container {
	overflow: auto;
	border-radius: 15px;
	position: relative;

	& > div {
		width: fit-content;
	}

	.buttonWrapper {
		position: fixed;
		bottom: 60px;
		left: 50%;
		width: fit-content;
		transform: translateX(-50%);
		z-index: 3;
	}
}
