.background {
	position: fixed;
	width: 140dvw;
	height: 100dvh;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow: hidden;
	z-index: -1;
	opacity: 0.4;

	& > img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}
