.arrow {
	position: absolute;
	top: -50px;
	z-index: 1;
}

.arrowLeft {
	right: 50px;
}
.arrowRight {
	right: 20px;
}

.slideWrapper {
	& > div {
		width: fit-content;
	}
}
