.container {
	max-width: 378px;
	right: 0;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	z-index: 1;
}
