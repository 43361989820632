@import './_reset.scss';
@import './_fonts.scss';
@import './_colors.scss';
@import './_custom.scss';
@import './_theme.scss';
@import './_typography.scss';
@import './notif.scss';

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overscroll-behavior-y: contain;
	background-color: var(--elev100);
	background-size: cover;
}

* {
	font-family: 'Lato Regular';
	outline: none;
	-webkit-tap-highlight-color: transparent;
	user-select: none;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

// .focus {
// 	border: 2px solid var(--mono200);
// 	outline: none;
// }

video {
	cursor: pointer;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--mono100);
}

h1 {
	@extend .h1-m;
}
h2 {
	@extend .h2-m;
}
h3 {
	@extend .h3-m;
}
h4 {
	@extend .h4-m;
}
span,
p {
	@extend .body1-m;
}

input:-webkit-autofill {
	-webkit-background-clip: content-box;
	-webkit-text-fill-color: var(--mono200);
	-webkit-box-shadow: 0 0 0px 40rem var(--elev400) inset;
}
input:-internal-autofill-selected {
	appearance: menulist-button;
	background-image: none !important;
	background-color: -internal-light-dark(
		rgb(232, 240, 254),
		rgba(70, 90, 126, 0.4)
	) !important;
	color: fieldtext !important;
}

// ::-webkit-scrollbar {
// 	width: 4px;
// 	height: 4px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
// 	background: var(--mono300);
// 	border-radius: 5px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// 	background: var(--mono500);
// 	border-radius: 5px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// 	background: var(--mono500);
// }

.App {
	width: 100%;
	margin: 0 auto;
	max-width: var(--maxWidth);
	padding: 0 20px;
	// padding: 0 20px 0 70px;
	box-sizing: border-box;
	position: relative;
	height: 100%;
	min-height: 100dvh;
	display: flex;
	flex-direction: column;
}

:root {
	--navIndex: 3;
	--actionAnimationIndex: 97;
	--headerIndex: 98;
	--blurBackIndex: 99;
	--additionalSidebarIndex: 101;
	--sidebarIndex: 102;
	--modalIndex: 103;
	--tutorialOpenIndex: 104;
	--selectPopupIndex: 105;
	--toastIndex: 106;
	--loaderIndex: 111;
	--transition: 0.3s ease-in-out;
	--headerHeight: 97px;
	--footerHeight: 51px;
	--tutorialWidth: 247px;
	--navWidth: 64px;
	--sidebarWidth: 480px;
	--networkCardWidth: 185px;
	--layoutMaxWidth: calc(1440px - 64px - 20px);
	--maxWidth: 1440px;
	--contentCardHeight: 664px;
	--portfolioContentModalHeight: 490px;
	--videoAspect: 1.778;
	--castingUsersListWidth: 300px;
}

// @media only screen and (orientation: landscape) {
// 	body {
// 		height: 100vw;
// 		transform: rotate(90deg);
// 	}
// }

// .globalWorkspacePadding {
// 	padding: 10px 20px 15px;
// }

// .hideBodyOverflow {
// 	@media (orientation: 'portrait') {
// 		overflow: hidden;
// 	}
// }

// .StripeElement {
// 	display: block;
// 	padding: 10px 14px;
// 	border: 0;
// 	outline: 0;
// 	background-color: var(--mono400);
// }

// .hideScrollBar {
// 	-ms-overflow-style: none; /* IE and Edge */
// 	scrollbar-width: none; /* Firefox */
// 	&::-webkit-scrollbar {
// 		display: none;
// 	}
// }
