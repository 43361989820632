.container {
	display: grid;
	grid-template-columns: 180px 1fr;
	gap: 44px;

	& > div:first-of-type {
		margin: auto;

		& > input {
			z-index: 2 !important;
		}
	}
}

.modalWrapper {
	width: fit-content;
	height: fit-content;

	& > div {
		max-height: calc(100% - 48px - 24px);
		overflow-y: unset !important;
		justify-content: space-between;
		align-items: center;
	}
}
