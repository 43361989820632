@use 'sass:map';
@use 'sass:color';

$themes: (
	'actor': (
		'primary': map.get($colors, 'electric-tangerine-main'),
	),
	'agency': (
		'primary': map.get($colors, 'electric-tangerine-main'),
	),
	'castingdirector': (
		'primary': map.get($colors, 'electric-tangerine-main'),
	),
	'contributor': (
		'primary': map.get($colors, 'electric-tangerine-main'),
	),
);

@each $name, $theme in $themes {
	.theme-#{$name} {
		@each $name, $color in $theme {
			--theme-#{$name}: #{$color};
			// --theme-#{$name}-transparent: #{color.change($color, $alpha: 0)};
		}
	}
}
