.container {
	max-width: 300px;
	width: 100%;
	padding: 12px 15px;
	cursor: pointer;
	background-color: var(--elev300);
	height: fit-content;
	position: relative;
	overflow: hidden;

	.duration {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}
