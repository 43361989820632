.container {
	display: flex;
	width: 100%;
	position: relative;

	& > img {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;

		@media (min-width: 768px) {
			position: relative;
			object-fit: unset;
		}
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: relative;
		z-index: 1;
		gap: 8px;
		width: 100%;
		padding: 16px;
		box-sizing: border-box;

		@media (min-width: 768px) {
			max-width: 562px;
			width: calc(100% - 40px);
			gap: 12px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		& > button {
			margin-top: 16px;

			@media (min-width: 768px) {
				margin-top: 20px;
			}
		}

		& > h2 {
			font-size: 24px;
			font-weight: 500;
			line-height: 30px;
		}
		& > span {
			font-size: 16px;
			line-height: 19px;
			color: var(--mono200);
			font-weight: 400;
		}

		@media (min-width: 768px) {
			& > h2 {
				font-size: 38px;
				line-height: 48px;
			}
			& > span {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}
}
