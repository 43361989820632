.tagsWrapper {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 10px;

	.tag {
		border-radius: 7px;
		border: 1px solid var(--mono100);
		padding: 3px 5px;
		position: relative;

		&:hover {
			.remove {
				display: flex;
			}
		}

		.remove {
			display: none;
			position: absolute;
			top: -5px;
			right: -5px;
			z-index: 1;
			background-color: var(--mono500);
			border-radius: 50%;
			cursor: pointer;
		}
	}
}
