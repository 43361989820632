.container {
	display: flex;
	flex-direction: column;
	gap: 44px;
	background-color: var(--elev100);
	height: 100%;
	overflow: unset !important;

	@media (min-width: 768px) {
		gap: 72px;
	}
}
