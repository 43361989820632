.container {
	display: flex;
	flex-direction: column;
	gap: 10px;

	& > span {
		color: var(--mono300);
		white-space: nowrap;
	}

	.wrapper {
		display: flex;
		align-items: center;
		gap: 5px;
		flex-wrap: wrap;
	}
}
