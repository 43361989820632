.container {
	padding-bottom: 0;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row !important;
	position: relative;

	& > div:first-of-type {
		position: initial;
		height: 50px;
		width: 85px;
		min-width: 85px;
		max-width: 85px;
		background-color: var(--elev400) !important;
		border-radius: 15px;

		& > button {
			border-top-left-radius: 15px;
			border-bottom-left-radius: 15px;
			width: 100%;
			height: 100%;
			border: none;
			background-color: var(--elev400);
		}

		& > ul {
			background-color: var(--elev400) !important;
			border-radius: 15px;
			width: 100%;
			// width: 200px;
			top: calc(100% + 5px);
			z-index: 2 !important;

			& > li {
				padding: 8px;
				background-color: var(--elev400);

				&:hover {
					background-color: var(--mono500);
				}
				&[aria-selected='true'] {
					background-color: var(--mono500);
				}
			}
		}
	}
}

.input {
	padding: 12px 20px !important;
	padding-left: 0px !important;
	border-radius: 15px !important;
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	color: var(--mono200) !important;
	background-color: var(--elev400) !important;
	border: 1px solid transparent !important;
	height: auto !important;
	width: auto !important;
	font-size: 16px !important;
	line-height: 24px !important;
	font-family: 'Lato Regular' !important;
	width: 100% !important;

	&::placeholder {
		text-transform: capitalize;
	}
}
