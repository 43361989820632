@import 'styles/_functions.scss';

.container {
	padding: 0px 82px;
	box-sizing: border-box;

	@media (max-width: 800px) {
		padding: 0;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;

		@media (min-width: 768px) {
			gap: 32px;
		}

		.header {
			display: flex;
			flex-direction: column;
			gap: 8px;
			max-width: 100%;
			align-items: center;
			text-align: center;

			& > h3 {
				font-size: 24px;
				line-height: 30px;
				font-weight: 500;
			}
			& > span {
				font-size: 16px;
				line-height: 19px;
				color: var(--mono300);
			}

			@media (min-width: 768px) {
				max-width: 75%;
				gap: 12px;

				& > h3 {
					font-size: 38px;
					line-height: 48px;
				}
				& > span {
					font-size: 24px;
					line-height: 28px;
				}
			}
		}

		.player {
			position: relative;

			.control {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				padding: 12px;
				box-sizing: border-box;
				background-color: bg-t(var(--mono900), 30%);
				cursor: pointer;
				display: flex;
				border-radius: 50%;
				z-index: 1;
				transition: var(--transition);
			}

			& > video {
				border-radius: 20px;
				overflow: hidden;
				width: 100%;
				aspect-ratio: 16 / 9;
			}
		}

		.playing {
			.control {
				visibility: hidden;
				opacity: 0;
			}

			&:hover {
				.control {
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
}
