@import 'styles/_functions.scss';

.container {
	display: flex;
	flex-direction: column;
	background-color: var(--mono600);
	border-radius: 25px;
	min-height: calc(var(--contentCardHeight) + 88px);

	.wrapper {
		border-radius: 25px;
		position: relative;
		overflow: hidden;
		display: flex;
		gap: 12px;
	}
}
.sticky {
	position: fixed !important;
	top: 0px;
	z-index: 1;
	margin-right: 20px;
	width: 100%;
	max-width: calc(min(100%, var(--maxWidth)) - var(--navWidth) - 40px - 20px);
}

.animationWrapper {
	transition: all 0.3s ease-in-out;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0;
	// overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	background-color: var(--mono600);

	& > div {
		padding-top: 0;
	}
}

$sec: 0.3s;

/* left direction */
.moveToLeft {
	-webkit-animation: moveToLeft $sec ease both;
	animation: moveToLeft $sec ease both;
}
.moveFromRight {
	-webkit-animation: moveFromRight $sec ease both;
	animation: moveFromRight $sec ease both;
}

/* right direction */
.moveToRight {
	-webkit-animation: moveToRight $sec ease both;
	animation: moveToRight $sec ease both;
}
.moveFromLeft {
	-webkit-animation: moveFromLeft $sec ease both;
	animation: moveFromLeft $sec ease both;
}

@-webkit-keyframes moveToLeft {
	from {
	}
	to {
		-webkit-transform: translateX(-100%);
	}
}
@keyframes moveToLeft {
	from {
	}
	to {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

@-webkit-keyframes moveFromLeft {
	from {
		-webkit-transform: translateX(-100%);
	}
}
@keyframes moveFromLeft {
	from {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

@-webkit-keyframes moveToRight {
	from {
	}
	to {
		-webkit-transform: translateX(100%);
	}
}
@keyframes moveToRight {
	from {
	}
	to {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

@-webkit-keyframes moveFromRight {
	from {
		-webkit-transform: translateX(100%);
	}
}
@keyframes moveFromRight {
	from {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

.showShort {
	visibility: visible !important;
	opacity: 1 !important;
}
