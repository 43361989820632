@import 'styles/_functions.scss';

.wrapper {
	padding: 12px 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 12px;

	.header {
		width: 100%;
		cursor: pointer;
		position: relative;

		& > div:first-of-type {
			transition: var(--transition);
			position: absolute;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
		}

		.headerWrapper {
			visibility: visible;
			opacity: 1;
			transition: var(--transition);
			display: flex;
			justify-content: space-between;
			align-items: center;

			& > div {
				display: flex;
			}
		}
	}
	.showInput {
		& > div:first-of-type {
			visibility: visible;
			opacity: 1;
		}

		.headerWrapper {
			visibility: hidden;
			opacity: 0;
		}
	}

	.listWrapper {
		overflow-x: auto;
		height: 100%;
	}
	.list {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

.activeTab {
	span {
		color: var(--theme-primary) !important;
	}
	path {
		fill: var(--theme-primary);
	}
	div {
		border-color: var(--theme-primary) !important;
	}
}

.notifyLabel {
	width: fit-content;
	display: flex;
	margin-bottom: 10px;
	text-decoration: underline;
}
