// .notifClass {
// 	& > button {
// 		color: var(--mono400);
// 		opacity: 1;
// 		margin: auto 0;
// 		padding: 12px;
// 	}
// }

.notifInfoClose {
	& > button {
		& > svg > path {
			fill: var(--warning);
		}
	}
}

.notifSuccessClose {
	& > button {
		& > svg > path {
			fill: var(--success);
		}
	}
}

.notifErrorClose {
	& > button {
		& > svg > path {
			fill: var(--error);
		}
	}
}

.notifBodyClass {
	& > div:first-of-type {
		width: 50px;
	}
}

.notifMessageClass {
	display: flex;
	flex-direction: column;
	gap: 4px;

	& > span:last-of-type {
		color: var(--mono200);
	}
}

.notifIconWrapper {
	display: flex;
	padding: 12px;
	border-radius: 50px;
}

.defaultToast {
	& > div > svg > path {
		fill: var(--theme-primary);
	}
}
