@import 'styles/_functions.scss';

@mixin btn-style {
	padding: 12px 32px;
	border-radius: 25px;
	cursor: pointer;
	transition: var(--transition);
	display: flex;
	justify-content: center;

	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
}

.themed {
	@include btn-style;
	background-color: bg-t(var(--theme-primary), 20%);
	color: var(--theme-primary);

	&:hover {
		background-color: bg-t(var(--theme-primary), 30%);
	}
}

.default {
	@include btn-style;
	background-color: transparent;
	color: var(--mono400);

	&:hover {
		background-color: bg-t(var(--mono400), 20%);
	}
}

.filled {
	@include btn-style;
	color: var(--mono100);
	background-color: bg-t(var(--mono200), 20%);

	&:hover {
		background-color: bg-t(var(--mono200), 40%);
	}
}

.outlined {
	@include btn-style;
	color: var(--mono100);
	background-color: transparent;
	border: 2px solid var(--mono100);
	padding-top: 10px;
	padding-bottom: 10px;

	&:hover {
		background-color: bg-t(var(--mono400), 20%);
	}
}

.black {
	color: var(--mono900);
	border: 2px solid var(--mono900);
}

.white {
	border-color: 2px solid var(--mono100);
	color: var(--mono100);
	background-color: var(--mono900);

	&:hover {
		background-color: bg-t(var(--mono900), 70%);
	}
}
