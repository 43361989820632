.avatar {
	border: 2px solid transparent;
	padding: 0;
	z-index: 2;
	position: relative;

	& > img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 36px;
		height: 36px;
		border-radius: 50%;
		border: 2px solid var(--mono900);
		object-fit: cover;
	}
}

.divider {
	width: 100%;
	height: 1px;
	background-color: var(--mono400);
}

.item {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 6px 20px 6px 12px;
	background-color: transparent;
	transition: var(--transition);
	cursor: pointer;

	&:hover {
		background-color: var(--elev300);
	}

	& > div {
		display: flex;
	}

	& > span {
		white-space: nowrap;
	}
}
