@import 'styles/_custom.scss';

.skill {
	display: flex;
	position: relative;
	cursor: pointer;

	.hexAnimWrapper {
		display: flex;
		@extend .highlight;
		animation-iteration-count: infinite;

		& > svg {
			z-index: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.wrapper {
		display: grid;
		grid-template-rows: repeat(3, minmax(0, 1fr));
		background-color: var(--mono600);
		height: 70px;
		width: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		& > span {
			text-align: center;

			&:first-of-type,
			&:last-of-type {
				color: var(--mono300);
				font-size: 8px;
				line-height: 10px;
				text-transform: uppercase;
			}

			&:last-of-type {
				align-self: flex-end;
			}
		}

		.value {
			font-size: 14px;
			line-height: 16px;
			align-self: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
