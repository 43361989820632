.container {
	display: flex;
	flex-direction: column;
	position: relative;
	cursor: pointer;
	width: 100%;

	.fieldWrapper {
		position: relative;
		width: 100%;
		overflow: hidden;

		.handler {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			padding: 0;
			border: 0;
			opacity: 0;
		}

		.reset {
			position: absolute;
			right: 50px;
			bottom: calc(50% - 24px);
			display: flex;
			z-index: 1;
		}
		.arrow {
			position: absolute;
			right: 20px;
			bottom: calc(50% - 24px);
			display: flex;
		}
	}

	& > div > span {
		text-transform: capitalize;
	}
}
.options {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--elev300);
	border-radius: 15px;
	max-height: 200px;
	overflow: scroll;
}

.option {
	box-sizing: border-box;
	padding: 12px 20px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	cursor: pointer;

	&:hover {
		background-color: var(--elev200);
	}

	.labelWrapper {
		display: flex;
		align-items: center;

		.color {
			width: 15px;
			height: 15px;
			border-radius: 50%;
			margin-left: auto;
		}
	}

	.hint {
		color: var(--mono300);
	}
}
