.container {
	width: 100%;
	padding: 20px 0 0;
	position: absolute;
	z-index: 1;
	bottom: -29px;
	cursor: pointer;

	.tooltip {
		background-color: var(--mono600);
		position: absolute;
		top: -15px;
		padding: 2px 5px;
		box-sizing: border-box;
		border-radius: 15px;
	}

	.marker {
		height: 8px;
		width: 3px;
		background-color: var(--mono100);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		position: absolute;
		top: 2px;
		z-index: 3;
		cursor: pointer;

		&:hover {
			.markerItem {
				visibility: visible;
				opacity: 1;
			}
		}

		.markerItem {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			background-color: var(--mono100);
			display: flex;
			border-radius: 50%;
			bottom: 6px;
			padding: 4px;
			box-sizing: border-box;
			left: 50%;
			transform: translateX(-50%);
			transition: var(--transition);
		}
	}

	// .thumb {
	// 	height: 12px;
	// 	width: 12px;
	// 	background-color: var(--mono100);
	// 	border-radius: 50%;
	// 	position: absolute;
	// 	z-index: 3;
	// 	top: 50%;
	// 	transform: translate(-50%, -50%);
	// }

	.progressBar {
		width: 100%;
		height: 2px;
		z-index: 2;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		border-radius: 9px;
	}
}
