.item {
	background-color: var(--elev200);
	width: 100%;
	min-height: min(calc((var(--layoutMaxWidth) - 256px) / 3), calc(100dvw / 3));
	border-radius: 44px;
	overflow: hidden;
	position: relative;
	display: flex;
	height: 100%;

	@media (max-width: 1024px) {
		min-height: min(
			calc((var(--layoutMaxWidth) - 120px) / 3),
			calc(100dvw / 3)
		);
	}

	@media (max-width: 768px) {
		min-width: 100%;
		min-height: calc(100vw - 80px);
	}

	& > img {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.content {
		width: fit-content;
		margin-top: auto;
		box-sizing: border-box;
		padding: 16px;
		position: relative;
		z-index: 1;
		width: 100%;

		@media (min-width: 768px) {
			padding: 32px;
		}

		.backgroundShadow {
			background-color: var(--elev300);
			filter: blur(30px);
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
		}

		& > h3 {
			font-size: 22px;
			line-height: 27px;
			font-weight: 500;
			text-align: center;

			@media (min-width: 768px) {
				text-align: unset;
			}
		}

		& > img {
			width: calc(100% + 32px);
			margin-top: auto;
		}
	}
}

.item_landing {
	&:first-child {
		min-height: 370px !important;

		@media (min-width: 768px) {
			min-height: 1000px;
		}
	}
}
