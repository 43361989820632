.wrapper {
	padding-bottom: 8px;
	padding-left: 20px;
	display: flex;
	align-items: center;

	& > div {
		width: 12px;
		height: 12px;
		display: flex;
		position: relative;
		margin-right: 8px;

		& > svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.label {
		color: var(--mono200);
	}
}
