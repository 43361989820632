@import 'styles/_functions.scss';

.container {
	visibility: hidden;
	opacity: 0;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	padding: 10px 24px;
	box-sizing: border-box;
	gap: 24px;
	border-radius: 1111px !important;

	.control {
		padding: 10px;
		box-sizing: border-box;
		border-radius: 50%;
		transition: var(--transition);
		display: flex;
		cursor: pointer;

		& > svg > path {
			transition: var(--transition);
			fill: var(--mono300);
		}

		&:hover {
			background-color: bg-t(var(--mono300), 10%);

			path {
				fill: var(--mono100);
			}
			span {
				color: var(--mono100) !important;
			}
		}
	}
}
