.container {
	display: flex;
	flex-direction: column;

	.wrapper {
		display: grid;
		grid-template-columns: repeat(4, minmax(auto, 1fr));
		gap: 12px;

		.color {
			cursor: pointer;
			box-sizing: border-box;
			padding: 12px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 13px;

			& > div > div {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				box-sizing: border-box;
				transition: background-color 0.3s ease-in-out;
			}
		}
	}
}
