@import 'styles/_functions.scss';

.container {
	overflow: hidden;

	@media (min-width: 768px) {
		// margin-left: -20px;
		// margin-right: -20px;
	}
}

.arrow {
	position: absolute;
	z-index: 10;
	top: 50%;
	transform: translateY(-50%);
	padding: 16px;
	box-sizing: border-box;
	backdrop-filter: blur(12px);
	background-color: rgba(0, 0, 0, 0.64);
	display: flex;
	overflow: hidden;
}

.arrowLeft {
	left: 0;
	border-bottom-right-radius: 12px;
	border-top-right-radius: 12px;
}
.arrowRight {
	right: 0;
	border-bottom-left-radius: 12px;
	border-top-left-radius: 12px;
}
