.container {
	display: flex;
	flex-direction: column;
	width: 100%;

	.wrapper {
		padding-top: 12px;
		padding-bottom: 12px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 5px;
	}
}
