.wrapper {
	display: flex;
	// align-items: center;
	gap: 24px;

	.files {
		flex-direction: column;
		// width: 100%;
		align-items: center;
		gap: 20px;
	}

	.fields {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 20px;
	}
}
