.container {
	width: 230px;
	height: 100%;
	margin-left: 16px;
	box-sizing: border-box;
	position: relative;
	background-color: var(--elev200);

	.wrapper {
		border-radius: 15px;
		background-color: var(--elev300);
		display: flex;
		flex-direction: column;
		padding: 12px 20px;
		box-sizing: border-box;
		gap: 12px;

		.header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			& > div {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.list {
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			gap: 5px;
			max-height: 500px;
			height: 100%;

			.user {
				display: flex;
				align-items: center;
				gap: 12px;
				cursor: pointer;
				padding: 14px 12px;
				box-sizing: border-box;
				border-radius: 15px;
				transition: var(--transition);

				&:hover {
					background-color: var(--elev500);
				}

				.avatar {
					height: 38px;
					width: 38px;
					border-radius: 50%;
					overflow: hidden;

					& > img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					gap: 2px;
					max-width: calc(100% - 50px);
				}
			}
			.active {
				background-color: var(--elev500);
			}
		}
	}
}
