@import 'styles/_functions.scss';

.wrapper {
	display: flex;
	align-items: center;

	.substepsWrapper {
		display: flex;
		align-items: center;
		gap: 14px;
		transition: var(--transition);
		width: 0px;
		overflow: hidden;
	}
}

.substep {
	padding: 10px;
	box-sizing: border-box;
	cursor: pointer;
	border-radius: 50%;
	transition: var(--transition);
	background-color: transparent;

	&:hover {
		background-color: var(--elev400);
	}

	& > div {
		transition: var(--transition);
		border-radius: 50%;
		width: 8px;
		height: 8px;
		background-color: var(--mono400);
	}
}

.active {
	background-color: bg-t(var(--theme-primary), 20%);

	& > span {
		color: var(--theme-primary);
		transition: var(--transition);
	}

	& > div {
		background-color: var(--theme-primary);
	}
}
