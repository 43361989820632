.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	gap: 24px;
	height: 100%;
	position: relative;
	z-index: 1;
	box-sizing: border-box;

	@media (min-width: 768px) {
		padding: 16px;
		gap: 28px;
	}
}

.carouselWrapper {
	position: relative;
	display: flex;
	width: 100%;
	overflow: hidden;
	height: 100%;
	border-radius: 44px;

	& > button {
		display: none;
	}

	@media (min-width: 768px) {
		height: unset;
		& > button {
			display: inherit;
		}
	}

	.progress {
		position: absolute;
		bottom: 0;
		width: 0px;
		transition: var(--transition);
		left: 0;
		height: 6px;
		background-color: var(--mono100);
		z-index: 1;
	}
	.progressAnimation {
		animation-name: progressLine;
		animation-duration: 10s;
		animation-timing-function: linear;
	}
}

.titles {
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	justify-content: center;
	text-align: center;

	& > h1 {
		font-size: 34px;
		line-height: 38px;
		font-weight: 500;
	}
	& > h3 {
		font-size: 18px;
		line-height: 22px;
		font-weight: 500;
		color: var(--mono300);
	}

	@media (min-width: 768px) {
		& > h1 {
			font-size: 49px;
			line-height: 55px;
		}
		& > h3 {
			font-size: 24px;
			line-height: 28px;
			color: var(--mono100);
		}
	}
}

.shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-height: 100dvh;
	height: 100%;
}

.itemWrapper {
	height: 100%;
	width: 100%;
	position: relative;
	border-radius: 44px;
	overflow: hidden;
	min-height: 440px;

	@media (min-width: 768px) {
		max-height: 529px;
		min-height: unset;
	}

	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.info {
		position: absolute;
		bottom: 16px;
		left: 16px;
		display: flex;
		flex-direction: column;
		align-items: start;
		text-align: unset;
		gap: 8px;
		width: 100%;
		max-width: calc(100% - 32px);

		@media (min-width: 768px) {
			max-width: 600px;
			text-align: center;
			justify-content: center;
			align-items: center;
			bottom: 36px;
			left: 50%;
			transform: translateX(-50%);
		}

		.tag {
			margin-bottom: 8px;
			border-radius: 1111px;
			background-color: rgba(255, 255, 255, 0.64);

			& > span {
				color: #000000;
				padding: 4px 12px;
				box-sizing: border-box;
			}
		}

		& > h2 {
			font-size: 24px;
			line-height: 26px;
			font-weight: 500;
			color: #fafafa;
		}

		& > span {
			font-size: 16px;
			line-height: 19px;
			font-weight: 400;
			color: #fafafa;
		}

		@media (min-width: 768px) {
			& > h2 {
				font-size: 36px;
				line-height: 40px;
			}

			& > span {
				font-size: 22px;
				line-height: 26px;
				color: var(--mono200);
				font-weight: 500;
			}
		}
	}
}

.buttons {
	display: flex;
	align-items: center;
	gap: 32px;

	@media (max-width: 1000px) {
		flex-direction: column;
		align-items: self-start;
	}

	@media (max-width: 768px) {
		flex-direction: row;
		gap: 8px;

		& > button {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
}

@keyframes progressLine {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
