.container {
	transition: var(--transition);
	border-radius: 12px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:hover {
		background-color: var(--elev400);

		.arrow {
			visibility: visible;
			opacity: 1;
			width: 48px;
		}
	}
}

.wrapper {
	padding: 8px 16px 10px;
	display: flex;
	gap: 8px;
	flex-direction: column;
	width: 100%;

	.titles {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			line-height: 21px;
		}

		.label {
			display: flex;
			gap: 4px;
		}
	}

	.lineWrapper {
		width: 100%;
		position: relative;
		background-color: var(--elev500);
		height: 4px;
		border-radius: 5px;

		& > div {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
			height: 8px;
			border-radius: 5px;
		}
	}
}

.arrow {
	display: flex;
	// padding: 12px;
	visibility: hidden;
	opacity: 0;
	transition: var(--transition);
	width: 0;
}
