.container {
	width: 282px;
	height: 424px;
	// border-radius: 24px;
	// overflow: hidden;
	padding: 0;
	overflow: hidden;
	position: relative;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
	}

	.wrapper {
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 2;
		margin-top: auto;

		.location {
			display: flex;
			align-items: center;
			gap: 10px;

			& > span {
				color: var(--mono200);
			}
		}
	}
}
