.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	// padding-bottom: 20px;
	position: relative;
}
.startAdornment {
	position: absolute;
	bottom: 15px;
	left: 20px;
}
.endAdornment {
	position: absolute;
	bottom: 13px;
	right: 20px;
	display: flex;
}

.input {
	padding: 12px 20px;
	border-radius: 15px;
	color: var(--mono200);
	background-color: var(--elev400);
	border: 1px solid transparent;

	&:disabled {
		color: var(--mono400);
	}
}

.aAdornment {
	padding-left: 50px;
}
.eAdornment {
	padding-right: 50px;
}

.default {
	background-color: transparent;
	// text-align: end;
	border-bottom: 1px solid var(--mono500);
	border-radius: 0;
}
