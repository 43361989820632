.container {
	overflow: hidden;
	display: flex;
	justify-content: center;

	.wrapper {
		display: flex;
		align-items: center;
		gap: 24px;
		overflow-x: auto;
		width: auto;
		max-width: 100%;

		@media (max-width: '768px') {
			gap: 12px;
		}
	}
}
