@import 'styles/_functions.scss';

.info {
	display: flex;
	flex-direction: column;
	gap: 20px;

	.dates {
		display: flex;
		position: relative;
	}
}
.picker {
	position: absolute;
	top: 0;
	z-index: 1;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	background-color: var(--mono600);
}

.prodTypeWrapper {
	display: flex;
	align-items: center;
	gap: 10px;
}

.scenes {
	display: flex;
	align-items: center;
	// padding: 0px 10px;
	box-sizing: border-box;
	gap: 20px;
}
