@import 'styles/_functions.scss';

.actions {
	visibility: hidden;
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	gap: 0px !important;
	overflow: hidden;
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;
	transition: var(--transition);
	z-index: 3;

	.action {
		background-color: bg-t(var(--mono100), 40%);
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		cursor: pointer;
		padding: 12px;
		box-sizing: border-box;
		transition: var(--transition);

		&:hover {
			background-color: bg-t(var(--mono100), 70%);
		}

		& > div {
			display: flex;
		}
	}
}
