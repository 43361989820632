@import 'styles/_functions.scss';

.container {
	background-color: var(--elev400);
	border-radius: 15px;
	cursor: pointer;

	.wrapper {
		display: flex;
		align-items: center;
		padding: 12px;
		gap: 12px;

		.checkBox {
			box-sizing: border-box;
			transition: var(--transition);
			background-color: transparent;
			width: 16px;
			height: 16px;
			min-width: 16px;
			min-height: 16px;
			border: 2px solid var(--mono400);
			position: relative;
			overflow: hidden;
			border-radius: 3px;

			& > svg {
				transition: var(--transition);
				opacity: 0;
				visibility: hidden;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.checked {
			border: none;

			& > svg {
				opacity: 1;
				visibility: visible;
			}
		}

		& > span {
			color: var(--mono400);
			transition: var(--transition);
		}
	}
}

.active {
	background-color: bg-t(var(--theme-primary), 20%);

	.wrapper {
		& > span {
			color: var(--theme-primary);
		}
	}
}
