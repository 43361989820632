@import 'styles/_functions.scss';

.container {
	cursor: pointer;
	background-color: transparent;
	transition: var(--transition);
	border-radius: 15px;
	min-width: 78px;
	min-height: 75px;
	max-width: 78px;
	// max-height: 75px;
	// height: 100%;
	width: 100%;

	.wrapper {
		padding: 12px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 10px;
		height: 100%;
		width: 100%;

		.color {
			width: 22px;
			height: 22px;
			border-radius: 50%;
			border: 1px solid var(--mono100);
		}

		& > span {
			text-align: center;
			transition: var(--transition);
			color: var(--mono400);
			white-space: break-spaces;
			margin: auto 0;
			// word-break: break-word;
		}
	}

	&:hover {
		background-color: bg-t(var(--elev400), 80%) !important;
		.wrapper {
			& > span {
				color: var(--mono100);
			}
		}
	}
}

.checked {
	background-color: var(--elev400);
	.wrapper {
		& > span {
			color: var(--mono100);
		}
	}
}
