@import 'styles/_functions.scss';

.blur {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--blurBackIndex);
	transition: var(--transition);
	background-color: bg-t(var(--elev200), 50%);
	backdrop-filter: blur(5px);
	visibility: visible;
}

.container {
	width: 100%;
	max-width: var(--sidebarWidth);
	position: fixed;
	top: 0;
	right: 0;
	z-index: var(--sidebarIndex);
	transition: var(--transition);
	transform: translateX(100%);
	height: 100%;
	background-color: var(--elev200);
	overflow-y: auto;
	overflow-x: hidden;

	.wrapper {
		display: flex;
		flex-direction: column;
		padding: 34px 44px 0px;
		box-sizing: border-box;
		gap: 20px;

		.header {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.titleWrapper {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: 10px;

				& > div {
					cursor: pointer;
					display: flex;
				}
			}
		}

		.close {
			position: absolute;
			top: 34px;
			right: 50px;
			z-index: 3;
			cursor: pointer;
		}
	}

	.buttons {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-top: auto;
	}
}

.open {
	transform: translateX(0);
}
.additional {
	background-color: var(--elev300);
	z-index: var(--additionalSidebarIndex);
}
.openAdditional {
	transform: translateX(-100%);
}
