@import 'styles/_functions.scss';

.container {
	gap: 10px;
	display: flex;
	flex-direction: column;
}

.highlight {
	background-color: bg-t(var(--theme-primary), 30%);
	border-radius: 5px;
}

.levelLabel {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding: 0 20px;
	box-sizing: border-box;
	height: 100%;
	display: flex;
	align-items: center;
	gap: 8px;
	cursor: pointer;

	& > span {
		margin-left: auto;
	}

	.arrow {
		display: flex;
	}
}
