.controls {
	position: absolute;
	top: 33px;
	right: 44px;
	display: flex;
	align-items: center;
	gap: 5px;
}

.close {
	cursor: pointer;
	display: flex;
}

.arrow {
	padding: 0;
	display: flex;
}
