.container {
	width: 100%;
	height: 100%;
	position: relative;

	.wrapper {
		display: flex;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
