@import 'styles/_typography.scss';

.tagsWrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;

	.tags {
		display: flex;
		align-items: center;
		gap: 12px;
		flex-wrap: wrap;

		.tag {
			background-color: var(--elev400);
			border-radius: 12px;
			padding: 4px 12px;
			box-sizing: border-box;
			cursor: pointer;
			padding-top: 1px;

			& > span {
				color: var(--mono300);
				@extend .caption-m;
			}
		}
	}
}
