.container {
	display: flex;
	flex-direction: column;
	cursor: pointer;

	.card {
		background-color: var(--elev400);
		border-radius: 15px;
		padding: 24px 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;

		.title {
			display: flex;
			align-items: center;
			gap: 10px;
			width: fit-content;
		}

		.image {
			width: 65px;
			height: 85px;
			border-radius: 15px;
			overflow: hidden;

			& > img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
