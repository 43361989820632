.container {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 32px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 44px;
	align-items: center;
	width: 60%;

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
	}

	& > button {
		width: 35%;
	}

	.lineWrapper {
		position: relative;
		display: flex;
		align-items: center;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 16px;

		.items {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: center;

			.item {
				display: flex;

				& > svg > path {
					transition: var(--transition);
				}
				& > svg > rect {
					transition: var(--transition);
				}
			}

			.iconWrapper {
				padding: 3px;
				box-sizing: border-box;
				border-radius: 50%;
				border: 1px solid transparent;
				display: flex;
			}
		}
	}
}
