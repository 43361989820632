.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	height: 100%;
	min-height: 206px;
	position: relative;
	z-index: 1;

	& > div {
		display: flex;
	}
}

.image {
	position: absolute;
	top: 0;
	left: 0;
}
