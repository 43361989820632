@import 'styles/_functions.scss';
$cardSize: 121px;

.list {
	// display: grid;
	gap: 8px;
	// min-width: calc(100% - 4px);
	// max-width: calc(100% - 4px);
	// grid-template-columns: repeat(3, 1fr);
	// grid-template-rows: repeat(3, 1fr);
	display: flex;
	flex-direction: column;
	width: 100%;

	& > div {
		border: 2px solid transparent;
		// width: $cardSize;
		// height: $cardSize;
		transition: var(--transition);
		cursor: pointer;
		border-radius: 15px;
		overflow: hidden;
		opacity: 0.5;
		position: relative;

		// &:nth-child(4n + 1) {
		// 	grid-column: span 2;
		// 	width: calc($cardSize * 2 + 12px - 4px);
		// }
		// &:nth-child(4n + 2) {
		// 	grid-row: span 2;
		// 	height: calc($cardSize * 2 + 12px);
		// }
		// &:nth-child(4n + 3) {
		// 	grid-column: span 2;
		// 	grid-row: span 2;
		// 	width: calc(100% - 4px);
		// 	height: calc(100% - 4px);
		// }
		// &:nth-child(4n + 4) {
		// 	grid-column: span 1;
		// 	grid-row: span 1;
		// }

		&:hover {
			opacity: 1;
			& > span {
				visibility: visible;
				opacity: 1;
			}
		}

		& > img {
			width: 100%;
			// height: 100%;
			// object-fit: cover;
			object-position: top;
		}

		& > span {
			position: absolute;
			bottom: 10px;
			left: 10px;
			max-width: calc(100% - 20px);
			visibility: hidden;
			opacity: 0;
			transition: var(--transition);
		}
	}

	.active {
		opacity: 1;
		border: 2px solid var(--mono100);
	}
	.disabled {
		// cursor: default;
		border: 2px solid var(--warning);
		&:hover {
			// opacity: 0.5;
		}
	}
}
