@import 'styles/_functions.scss';

.container {
	cursor: pointer;
	display: flex;
	align-items: flex-start;
	gap: 10px;
	padding-left: 10px;

	&:hover > .wrapper {
		background-color: bg-t(var(--theme-primary), 20%);
	}

	.wrapper {
		box-sizing: border-box;
		transition: var(--transition);
		background-color: transparent;
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
		border: 2px solid var(--mono400);
		position: relative;
		overflow: hidden;
		border-radius: 3px;

		& > svg {
			transition: var(--transition);
			opacity: 0;
			visibility: hidden;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.titles {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.checked {
		border: none;

		& > svg {
			opacity: 1;
			visibility: visible;
		}
	}
}
