@import 'styles/_custom.scss';

.wrapper {
	@extend .grid3column;

	@media (max-width: 800px) {
		display: flex;
		flex-direction: column;

		& > div:first-of-type {
			grid-template-columns: repeat(3, minmax(auto, 1fr));
		}
	}
}

.sidebar {
	display: flex;
	flex-direction: column;

	& > div:first-of-type {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
}
