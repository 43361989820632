@import 'styles/_custom.scss';

.gridWrapper {
	@extend .grid4column;
	@media (max-width: 1250px) {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
	@media (max-width: 950px) {
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
	@media (max-width: 600px) {
		grid-template-columns: repeat(1, minmax(auto, 1fr));
	}
}
