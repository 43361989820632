.crop {
	height: 100%;
	margin-top: 10px;
	max-width: calc(100dvw - 88px);
	max-height: calc(100dvh - 40px - 64px - 24px - 48px);

	& > div {
		height: 100%;
	}
}
