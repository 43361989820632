.container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding: 16px 20px;
	box-sizing: border-box;
	background: radial-gradient(
		112.9% 100% at 99.72% 0%,
		rgba(42, 158, 70, 0.12) 0%,
		rgba(42, 158, 70, 0) 100%
	);
	border-radius: 15px;
	overflow: hidden;
	width: 100%;
	min-height: 214px;
	height: 100%;
	cursor: pointer;
	position: relative;
	transition: opacity 0.3s ease-in-out;
	opacity: 0.7;

	&:hover {
		opacity: 1;
	}
}

.small {
	flex-direction: row;
	align-items: center;
	height: 100%;
	min-width: fit-content;
	min-height: fit-content;
	width: fit-content;

	.avatar {
		min-width: 72px;
		min-height: 72px;
	}
}

.agency-container {
	flex-direction: row;
	padding: 8px 12px;
	gap: 12px;
}

.infoWrapper {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.name {
	display: flex;
	gap: 4px;
	align-items: center;
}

.avatarWrapper {
	position: relative;
	width: fit-content;

	.status {
		display: flex;
		position: absolute;
		top: -5px;
		right: -5px;
	}

	.avatar {
		width: 72px;
		height: 72px;
		border-radius: 50%;
		overflow: hidden;
		border: 2px solid transparent;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.agency {
		width: 44px;
		height: 44px;
	}
}

.info {
	display: flex;
	align-items: center;
	gap: 8px;
	width: fit-content;
}

.contactsWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: auto;
	overflow: hidden;
}

.contacts {
	text-decoration: underline;
	color: var(--mono300);
	cursor: pointer;
	width: 100%;

	&:hover {
		color: var(--theme-primary);
	}
}
