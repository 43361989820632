$slideHeight: calc(100dvh - 64px - 40px - 72px - 48px - 44px - 64px);

.slide {
	height: $slideHeight;
	// width: fit-content;
	width: 100%;
	position: relative;

	.mediaWrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;

		& > img {
			border-radius: 15px;
			overflow: hidden;
			object-fit: contain;
			// width: 100%;
			height: 100%;
		}

		& > video {
			height: 100%;
			// width: 100dvw;
			// height: $slideHeight;
			// width: calc($slideHeight * var(--videoAspect));
		}
	}
}

.tagsPlaceholder {
	padding-bottom: 10px;
	height: 64px;
}

.tags {
	position: absolute;
	height: 64px;
	top: -74px;
	display: flex;
	align-items: center;
	gap: 20px;
	max-width: 100%;
	overflow: auto;

	.tag {
		display: flex;
		flex-direction: column;
		padding: 5px 10px;
		box-sizing: border-box;
		// background-color: var(--elev400);
		backdrop-filter: blur(12px);
		border-radius: 15px;
		border: 2px solid transparent;
		gap: 6px;

		& > span {
			white-space: nowrap;
		}
	}

	.activeTag {
		border: 2px solid var(--theme-primary);

		& > span:last-of-type {
			color: var(--theme-primary);
		}
	}
}
