.container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 420px;
	height: auto;

	@media (max-width: 768px) {
		max-width: calc(100% - 20px) !important;
		padding-left: 20px;
		padding-right: 20px;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 40px;

	@media (max-width: 768px) {
		gap: 20px;
	}

	.titles {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;

		& > * {
			text-align: center;
		}
		& > span {
			color: var(--mono300);
		}

		@media (max-width: 768px) {
			& > h2 {
				font-size: 24px;
				line-height: 28px;
			}
			& > span {
				font-size: 12px;
				line-height: 16px;
			}
		}
	}

	.buttonWrapper {
		position: relative;
		width: 100%;

		& > button {
			width: 100%;
			display: flex;
			align-items: center;
			gap: 12px;

			& > div:last-of-type {
				margin-left: auto;
			}
		}
	}

	.options {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 10px;

		& > span {
			cursor: pointer;
			color: var(--theme-primary);
		}
	}
}
