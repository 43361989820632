.container {
	display: flex;
	flex-direction: column;
}

.lineWrapper {
	padding: 16px;
	display: flex;
	gap: 8px;
	flex-direction: column;
	width: 100%;
	background-color: var(--elev300);
	border-radius: 15px;
	box-sizing: border-box;

	.titles {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			line-height: 21px;
		}

		.label {
			display: flex;
			gap: 4px;
		}
	}
}
.line {
	width: 100%;
	position: relative;
	background-color: var(--elev500);
	height: 8px;
	border-radius: 5px;

	& > div {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		height: 8px;
		border-radius: 5px;
	}
}

.lineHintWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
	box-sizing: border-box;
	gap: 16px;

	& > div {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}

.expWrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
