.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 20px;
	// min-height: calc(100dvh - 50px);
	padding-bottom: 20px;
	box-sizing: border-box;

	.wrapper {
		width: 100%;
		// height: 100%;
		align-items: flex-start;
		justify-content: space-between;
		gap: 12px;
		position: relative;

		& > div {
			width: 100%;
		}
	}
}

.wizard {
	overflow: hidden;
	margin-right: calc(var(--tutorialWidth) + 12px);
	background-color: var(--mono600);
	border-radius: 25px;
}
