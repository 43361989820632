@import 'styles/_functions.scss';

.controls {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 16px;
	box-sizing: border-box;
	background-color: bg-t(var(--elev200), 80%);
	gap: 32px;
	position: static;
	border-radius: 25px;

	.shortData {
		visibility: hidden;
		opacity: 0;
		transition: var(--transition);
		display: flex;
		gap: 12px;
		margin-right: auto;

		.avatar {
			width: 54px;
			height: 54px;
			border-radius: 50%;
			overflow: hidden;

			& > img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.name {
			display: flex;
			align-items: center;
		}

		.info {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
	.showShort {
		visibility: visible;
		opacity: 1;
	}

	.navWrapper,
	.nav {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.nav {
		cursor: pointer;
	}

	.hide {
		visibility: hidden;
		cursor: default;
	}
}
