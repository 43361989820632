@use 'sass:math';

.wrapper {
	width: 100%;
	display: flex;
	gap: 20px;
	height: 100%;
	// min-height: calc(100dvh - var(--headerHeight) - var(--footerHeight));
	transition: var(--transition);

	.main {
		width: 100%;
	}

	& > div:last-of-type {
		width: 100%;
		overflow: hidden;
	}
}

/* calculating the content width when filter sidebar opened */
/* if the screen is wide we can keep the maxWidth */
/* else we need to calculate the screen width to resize it like 100% - sidebarWidth */
.reduceWidth {
	width: min(
		calc(var(--maxWidth) - 40px),
		calc(
			min(var(--maxWidth), 100dvw) -
				(var(--sidebarWidth) - (max(2px, calc(100dvw - var(--maxWidth)))) / 2) -
				40px
		)
	);
}
