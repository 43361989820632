.container {
	position: absolute;
	width: 200px;
	height: 200px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	display: flex;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	transition: var(--transition);
	z-index: var(--actionAnimationIndex);
}

.show {
	transform: translate(-50%, -50%) scale(1);
}
