.infoWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.name {
		display: flex;
		align-items: center;
	}

	.info {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.contacts {
		text-decoration: underline;
		color: var(--mono200);
		cursor: pointer;

		&:hover {
			color: var(--theme-primary);
		}
	}
}
