.wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;

	& > div {
		width: 100%;
		& > div {
			max-width: 100%;
		}
	}
}
