@import 'styles/_functions.scss';

.container {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--blurBackIndex);
	transition: var(--transition);
	background-color: bg-t(var(--elev200), 50%);
	backdrop-filter: unset;
	visibility: hidden;
}

.show {
	backdrop-filter: blur(5px);
	visibility: visible;
}
