.container {
	display: flex;
	align-items: center;
	max-width: 100%;
	margin: auto;
	gap: 10px;
	overflow-y: auto;

	.item {
		height: 72px;
		width: 100px;
		min-width: 100px;
		opacity: 0.25;
		transition: opacity 0.3s ease-in-out;
		cursor: pointer;
		border-radius: 15px;
		overflow: hidden;

		& > img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.activeItem {
		opacity: 1;
	}
}
