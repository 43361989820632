.container {
	background-color: var(--elev200);
	border-radius: 44px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	padding: 16px 0;
	gap: 85px;
	box-sizing: border-box;

	@media (min-width: 768px) {
		padding: 80px 0;
		gap: 160px;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 12px;
	position: relative;
	padding: 0 16px;
	box-sizing: border-box;

	& > h2,
	& > span {
		position: relative;
		z-index: 1;
	}

	.shadow {
		position: absolute;
		width: 100%;
		z-index: 0;
		height: 100%;
	}

	.image {
		position: relative;
		z-index: 1;
	}

	&:first-of-type {
		.image {
			width: 100%;
			height: 300px;
			object-fit: cover;
			object-position: 35% 0;

			@media (min-width: 768px) {
				height: auto;
			}
		}
	}
	&:nth-child(2) {
		.shadow {
			height: 70%;
			bottom: 0;

			@media (min-width: 768px) {
				height: 100%;
				bottom: unset;
				top: 0;
			}
		}
		.image {
			position: relative;
			z-index: 3;
			@media (min-width: 768px) {
				padding: 0px 100px;
			}
		}
	}
	&:nth-child(3) {
		.image {
			position: relative;
			z-index: 1;
			width: 120%;
			object-fit: cover;
			transform: translateX(-40px);

			@media (min-width: 768px) {
				padding: 0 100px;
				box-sizing: border-box;
				transform: none;
				width: 100%;
			}
		}
		.shadow {
			position: absolute;
			width: 100%;
			top: 0;
			height: 100%;
		}
	}
	&:nth-child(4) {
		& > div {
			.image {
				width: 100%;
				border-radius: 32px;

				@media (min-width: 768px) {
					// transform: scale(1.7) translate(-65px, -90px);
					max-height: 600px;
					height: 100%;
				}
			}
		}
	}
	&:nth-child(5) {
		.image {
			width: 53px;
			margin-bottom: 20px;

			@media (min-width: 768px) {
				width: 84px;
			}
		}
	}

	h2 {
		font-size: 24px;
		line-height: 30px;
		font-weight: 500;
	}
	span {
		font-size: 16px;
		line-height: 19px;
		font-weight: 400;
	}

	@media (min-width: 768px) {
		h2 {
			font-size: 38px;
			line-height: 48px;
		}
		span {
			font-size: 24px;
			font-weight: 500;
			line-height: 28px;
		}
	}
}

.section3Content {
	position: absolute;
	bottom: -100px;
	left: 16px;
	max-width: 60%;
	display: flex;
	flex-direction: column;
	gap: 12px;
	text-align: start;
	z-index: 2;

	@media (min-width: 768px) {
		bottom: 10px;
		left: 150px;
		text-align: center;
		max-width: 40%;
	}
}

.section4Wrapper {
	box-sizing: border-box;
	align-items: center;
	margin: 70px 8px 0;
	padding: 0;

	@media (min-width: 768px) {
		padding: 0px min(170px, 8%) 0px;
		display: grid;
		grid-template-columns: repeat(2, minmax(auto, 1fr));
		gap: 53px;
		margin: 0;
	}

	.imageWrapper {
		overflow: hidden;
		border-radius: 32px;
	}

	.section4Content {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 10px;
		max-width: 80%;

		@media (min-width: 768px) {
			position: relative;
			text-align: start;
			gap: 12px;
		}
	}
}

.nextSection {
	display: flex;
	flex-direction: column;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	gap: 10px;
	position: absolute;
	bottom: 0px;
	right: 20px;

	@media (min-width: 768px) {
		bottom: 5%;
		right: 20%;
	}
}
