.tabs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	.tab {
		width: 100%;
		padding: 10px 20px;
		box-sizing: border-box;
		cursor: pointer;
		transition: var(--transition);
		text-align: center;
		border-bottom: 2px solid transparent;

		& > span {
			text-transform: capitalize;
			white-space: nowrap;
		}
	}
	.active {
		border-bottom: 2px solid var(--theme-primary);

		& > span,
		div {
			color: var(--theme-primary) !important;
		}
		svg > path {
			fill: var(--theme-primary);
		}
		div {
			border-color: var(--theme-primary) !important;
		}
	}
}
.fit {
	justify-content: unset;

	.tab {
		width: fit-content;

		& > span {
			white-space: nowrap;
		}
	}
}

.scroll {
	overflow-x: auto;
	max-width: 100%;
}
