.container {
	background-color: var(--elev300);
	border-radius: 15px;
	width: 100%;
	position: relative;
	height: fit-content;
	align-self: flex-end;

	.wrapper {
		padding: 12px;
		display: flex;
		align-items: center;
		gap: 12px;
		cursor: pointer;

		& > div {
			& > span {
				color: var(--mono300);
			}
		}

		.icon {
			display: flex;

			& > svg > path {
				fill: var(--mono300);
			}
		}

		.completed {
			display: flex;
		}
	}
	.active {
		& > div {
			& > span {
				color: var(--mono100);
			}
		}

		.icon > svg > path {
			fill: var(--mono100);
		}
	}
}
