.container {
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: relative;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;

		.avatar {
			width: 28px;
			height: 28px;
			min-width: 28px;
			border-radius: 50%;
			overflow: hidden;

			& > img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.contentWrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
}
.time {
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: end;
	cursor: pointer;
}
