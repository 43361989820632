.levelWrapper {
	position: relative;

	.track {
		height: 6px;
		width: 56px;
		background: linear-gradient(90deg, #f0b800 0%, #00d4ff 50%, #e3e3e3 100%);
		border-radius: 5px;
	}

	.line {
		position: absolute;
		top: 50%;
		left: 0%;
		transform: translateY(-50%);
		height: 16px;
		width: 2px;
		background-color: var(--mono100);
		border-radius: 5px;
	}
}
