@import 'styles/_custom.scss';

.container {
	@extend .grid5column;

	@media (max-width: 1300px) {
		grid-template-columns: repeat(4, minmax(auto, 1fr));
	}
	@media (max-width: 1100px) {
		grid-template-columns: repeat(3, minmax(auto, 1fr));
	}
	@media (max-width: 900px) {
		grid-template-columns: repeat(2, minmax(auto, 1fr));
	}
}

.sidebar {
	grid-template-columns: repeat(2, minmax(auto, 1fr));
}
