.container {
	width: 100%;
	border-radius: 15px;
	overflow: hidden;
	height: fit-content;
	position: relative;
	cursor: pointer;

	.background {
		height: 100%;
		width: 100%;
		position: absolute;
		filter: blur(5px);

		& > img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.wrapper {
		padding: 20px 16px;
		box-sizing: border-box;
		position: relative;
		z-index: 1;
		display: flex;
		gap: 16px;

		.avatar {
			border-radius: 12px;
			overflow: hidden;
			width: 72px;
			height: 72px;

			& > img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.content {
			display: flex;
			flex-direction: column;
			gap: 4px;
			justify-content: space-evenly;

			.name {
				display: flex;
				align-items: center;
			}

			.info {
				display: flex;
				align-items: center;
				gap: 10px;
			}
		}
	}
}
