.actions {
	display: flex;
	gap: 4px;
	margin-left: auto;
	margin-right: -12px;
	height: fit-content;
	// margin-top: auto;

	& > div {
		display: flex;
		cursor: pointer;
		padding: 12px;
		box-sizing: border-box;
	}
}
