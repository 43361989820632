@import 'styles/_functions.scss';

.container {
	display: flex;
	margin-top: auto;
	bottom: 20px;
	left: 20px;
	max-width: calc(100% - 40px);
	height: unset;
	width: 100%;
	overflow: unset;
	visibility: hidden;
	opacity: 0;
	position: relative;
	z-index: 2;

	.wrapper {
		padding: 10px 16px;
		box-sizing: border-box;
		gap: 20px;
		width: 100%;

		.group {
			display: flex;
			align-items: center;
			gap: 8px;
			// padding: 8px 0;

			& > div {
				cursor: pointer;
				display: flex;
			}

			.control {
				padding: 4px;
				box-sizing: border-box;
				border-radius: 50%;
				transition: var(--transition);
				display: flex;

				& > svg > path {
					transition: var(--transition);
					fill: var(--mono300);
				}

				&:hover {
					background-color: bg-t(var(--mono300), 10%);

					path {
						fill: var(--mono100);
					}
					span {
						color: var(--mono100) !important;
					}
				}
			}
		}
	}
}

.hideContent {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: var(--mono900);
}
