@import 'styles/_functions.scss';

.icons {
	width: 100%;
	display: flex;
	gap: 10px;
	justify-content: flex-end;
}

.headerDropdown {
	position: relative;
	display: flex;
	height: fit-content;
}

.icon {
	align-self: center;
	display: flex;
	cursor: pointer;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 50%;
	width: 44px;
	height: 44px;
	background-color: bg-t(var(--theme-primary), 20%);
	overflow: hidden;

	& > img {
		width: 100%;
	}
}

.dropdown {
	visibility: hidden;
	opacity: 0;
	transition: var(--transition);
	position: absolute;
	z-index: 3;
	background-color: var(--elev400);
	top: calc(100% + 10px);
	right: 0;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	overflow: hidden;
}
.show {
	visibility: visible;
	opacity: 1;
}
