@font-face {
	font-family: 'Comfortaa Medium';
	src: url('../assets/fonts/Comfortaa/Comfortaa-Medium.ttf');
	font-display: swap;
	font-weight: 500;
}
@font-face {
	font-family: 'Comfortaa Bold';
	src: url('../assets/fonts/Comfortaa/Comfortaa-Bold.ttf');
	font-display: swap;
	font-weight: 700;
}
@font-face {
	font-family: 'Lato Bold';
	src: url('../assets/fonts/Lato/Lato-Bold.ttf');
	font-display: swap;
	font-weight: 700;
}
@font-face {
	font-family: 'Lato Regular';
	src: url('../assets/fonts/Lato/Lato-Regular.ttf');
	font-display: swap;
	font-weight: 500;
}
@font-face {
	font-family: 'Lato Light';
	src: url('../assets/fonts/Lato/Lato-Light.ttf');
	font-display: swap;
	font-weight: 400;
}
@font-face {
	font-family: 'Lato Thin';
	src: url('../assets/fonts/Lato/Lato-Thin.ttf');
	font-display: swap;
	font-weight: 400;
}
// $helveticafont: 'Helvetica Neue Regular', sans-serif;

:root {
	--font-primary: 'Lato Regular';
}
