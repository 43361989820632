.container {
	display: flex;
	flex-direction: column;
	gap: 32px;
	justify-content: center;
	align-items: center;
	padding: 0px;
	box-sizing: border-box;

	@media (min-width: 768px) {
		padding: 0 60px;
	}

	& > h2 {
		font-size: 23px;
		line-height: 30px;
		text-align: center;
		font-weight: 500;

		@media (min-width: 768px) {
			font-size: 38px;
			line-height: 48px;
		}
	}

	.wrapper {
		display: grid;
		grid-template-columns: repeat(1, minmax(auto, 1fr));
		gap: 16px;
		width: 100%;

		@media (min-width: 768px) {
			gap: 30px;
			grid-template-columns: repeat(3, minmax(auto, 1fr));
		}
	}

	.item {
		width: 100%;
		position: relative;
		border-radius: 24px;
		overflow: hidden;
		height: 100%;
		max-height: 136px;
		cursor: pointer;

		@media (min-width: 768px) {
			max-height: 386px;
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 0 30%;

			@media (min-width: 768px) {
				object-position: unset;
			}
		}

		.titles {
			position: absolute;
			z-index: 1;
			bottom: 18px;
			left: 50%;
			transform: translateX(-50%);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			gap: 2px;

			& > span {
				font-size: 16px;
				line-height: 19px;
			}

			& > div {
				display: flex;
				align-items: center;

				& > h2 {
					font-size: 24px;
					line-height: 30px;
					white-space: nowrap;
					font-weight: 500;
				}
				@media (min-width: 768px) {
					& > h2 {
						font-size: 32px;
						line-height: 48px;
					}
				}
			}
		}
	}
}
