.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	padding: 10px 20px;
	box-sizing: border-box;
	max-width: 300px;
	width: 100%;
	cursor: pointer;

	.label {
		width: 100%;
		transition: var(--transition);
	}

	.switch {
		min-width: 30px;
		height: 16px;
		padding: 5px;
		box-sizing: border-box;
		border: 1px solid var(--mono400);
		border-radius: 6px;
		position: relative;
		transition: var(--transition);
		background-color: transparent;

		& > div {
			transition: var(--transition);
			position: absolute;
			top: 50%;
			left: 3px;
			transform: translateY(-50%);
			width: 10px;
			height: 10px;
			border-radius: 4px;
			background-color: var(--mono400);
		}
	}
	.active {
		border: 1px solid transparent;
		background-color: var(--theme-primary);

		& > div {
			background-color: var(--mono900);
			left: calc(100% - 12px);
		}
	}
}
