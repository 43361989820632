.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 20px;
	padding-bottom: 20px;
	box-sizing: border-box;
	// overflow: hidden;

	.wrapper {
		width: 100%;
		align-items: flex-start;
		justify-content: space-between;
		gap: 12px;
		position: relative;

		& > div {
			width: 100%;
		}
	}
}
