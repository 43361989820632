.hint {
	background-color: var(--elev400);
	padding: 8px 16px;
	box-sizing: border-box;
	gap: 12px;
	display: flex;
	align-items: center;
	border-radius: 10px;
	margin-top: auto;

	& > p {
		color: var(--mono300);
	}
}
