@mixin input {
	width: 100%;
	height: 100%;
	padding: 80px 20px;
	box-sizing: border-box;
	border-radius: 15px;
}

.container {
	display: flex;
	flex-direction: column;
	position: relative;

	.layout {
		@include input;
		border: 2px dashed var(--mono200);
		color: var(--mono200);
		background-color: var(--elev400);
		position: relative;
		overflow: hidden;

		& > img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		& > span {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			width: fit-content;
			display: flex;
			margin: 0 auto;
		}
	}
	.portrait {
	}
	.filled {
		border: 2px dashed var(--success);

		& > span {
			color: var(--success);
		}
	}
	.loading {
		border: 2px dashed var(--warning);

		& > span {
			color: var(--warning);
		}
	}

	.input {
		@include input;
		opacity: 0;
		position: absolute;
		z-index: 1;
		cursor: pointer;
	}
}
