@import 'styles/_functions.scss';

.container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.messageWrapper {
		display: flex;
		flex-direction: column;
		gap: 30px;
		margin-top: auto;
		overflow-x: auto;

		& > div:first-of-type {
			margin-top: auto;
		}
	}
}

.inputWrapper {
	display: flex;
	flex-direction: column;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	// padding: 16px 20px;
	background-color: bg-t(var(--mono000), 72%);

	textarea {
		background-color: transparent;

		&::placeholder {
			font-style: italic;
		}
	}

	.inputHint {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 4px 12px;
		box-sizing: border-box;

		.hint {
			display: flex;
			align-items: center;
			gap: 8px;

			& > div {
				display: flex;
			}

			& > span {
				color: var(--theme-primary);
			}
		}
	}
}
