.slide {
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.attachments {
	width: 100%;

	.files {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 10px;
		box-sizing: border-box;

		& > div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}
	}
}
