.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;

	.messageWrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: auto;
		overflow-x: auto;
	}
}
